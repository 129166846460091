import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

//Image Import
import AngleRightIcon from 'assets/images/common/angle-right-icon.svg';
import AngleLeftIcon from 'assets/images/common/angle-left-icon.svg';
import {ReactComponent as SettingsIcon} from 'assets/images/icons/settings.svg';
import {ReactComponent as ReportIcon} from 'assets/images/icons/report.svg';
import {ReactComponent as LearnerIcon} from 'assets/images/icons/learner.svg';
import {ReactComponent as ContentIcon}  from 'assets/images/icons/content.svg';
import {ReactComponent as DashboardIcon} from 'assets/images/icons/dashboard.svg';

const SideBar = () => {
	// 0 is used for Dashboard
	// 1 is used for Content
	// 2 is used for Learners
	// 3 is used for Reports
	type Menu = 0 | 1 | 2 | 3;
	const [mainSideBar, setMainSideBar] = useState<Menu>(0);
	const [currentSideBar, setCurrentSideBar] = useState('');
	//back to main menu
	const back = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setMainSideBar(0);
	};
	const handleSidebar = (menu: Menu) => {
		setMainSideBar(menu);
		let submenu = '';
		switch(menu) {
			case 0:
				submenu = '';
				break;
			case 1:
				submenu = '/content/overview';
				break;
			case 2:
				submenu = '/learners/overview';
				break;
			case 3:
				submenu = '/reports/overflow';
				break;
			default:
				throw new Error('main Side bar can only be 0, 1, 2, 3');
		}
		setCurrentSideBar(submenu);
	}
	const location = useLocation();
	useEffect(() => {
		setCurrentSideBar(location.pathname);
	}, [location.pathname]);

	return (
		<div className="sidebar-holder d-flex flex-column flex-shrink-0">
			<div
				className={
					mainSideBar === 0
						? 'sidebar d-flex flex-column flex-shrink-0'
						: 'sidebar d-flex flex-column flex-shrink-0 dropnav-active'
				}
			>
				<ul className="nav nav-pills flex-column mb-auto sidebar-nav-list">
					<li
						className={mainSideBar === 0 ? 'active' : ''}
						onClick={() => handleSidebar(0)}
					>
						<Link
							to="/dashboard"
							data-key="dashboard"
							className="nav-link nohasdrop-a"
							aria-current="page"
						>
							<div className="icon-wrap">	
								<DashboardIcon />
								<span className="sidebar-link-text">Dashboard</span>
							</div>
						</Link>
					</li>
					<li
						className={mainSideBar === 1 ? 'active' : ''}
						onClick={() => handleSidebar(1)}
					>
						<Link 
							to="/content/overview"
							className="nav-link hasdrop-a">
							<div className="icon-wrap">
								<ContentIcon />
								<span className="sidebar-link-text">Content</span>
							</div>
							<img className="arrow-icon" src={AngleRightIcon} alt="arrow" />
						</Link>
						<div className="sideabar-side-nav">
							<a className="back-nav" onClick={(event: any) => back(event)}>
								<img src={AngleLeftIcon} alt="content" />
								Content
							</a>
							<ul>
								<li>
									<Link
										to="/content/overview"
										className={
											currentSideBar === '/content/overview'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => setCurrentSideBar('/content/overview')}
									>
										Overview
									</Link>
								</li>
								<li>
									<Link
										to="/content/content-menu"
										className={
											currentSideBar === '/content/content-menu'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => setCurrentSideBar('/content/content-menu')}
									>
										Content Menu
									</Link>
								</li>
								{/* <li>
									<Link to="/" className="nav-link">
										Edit Content
									</Link>
								</li> */}
								{/* <li>
									<Link to="/" className="nav-link">
										Content Logs
									</Link>
								</li> */}
							</ul>
						</div>
					</li>
					<li
						className={mainSideBar === 2 ? 'active' : ''}
						onClick={() => handleSidebar(2)}
					>
						<Link 
							to="/learners/overview"
							className="nav-link hasdrop-a">
							<div className="icon-wrap">
								<LearnerIcon />
								<span className="sidebar-link-text">Learners</span>
							</div>
							<img className="arrow-icon" src={AngleRightIcon} alt="arrow" />
						</Link>
						<div className="sideabar-side-nav">
							<Link
								to="/"
								className="back-nav"
								onClick={(event: any) => back(event)}
							>
								<img src={AngleLeftIcon} alt="learners" />
								Learners
							</Link>
							<ul>
								<li>
									<Link
										to="/learners/overview"
										className={
											currentSideBar === '/learners/overview'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => setCurrentSideBar('/learners/overview')}
									>
										Overview
									</Link>
								</li>
								<li>
									<Link
										to="/learners/passwords"
										className={
											currentSideBar === '/learners/passwords'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => setCurrentSideBar('/learners/passwords')}
									>
										Passwords
									</Link>
								</li>
								{/* <li>
									<Link to="/" className="nav-link">
										User Logs
									</Link>
								</li> */}
							</ul>
						</div>
					</li>
					<li
						className={mainSideBar === 3 ? 'active' : ''}
						onClick={() => handleSidebar(3)}
					>
						<Link 
							to="/reports/overflow"
							className="nav-link hasdrop-a">
							<div className="icon-wrap">
								<ReportIcon />
								<span className="sidebar-link-text">Reports</span>
							</div>
							<img className="arrow-icon" src={AngleRightIcon} alt="arrow" />
						</Link>
						<div className="sideabar-side-nav">
							<Link
								to="/"
								className="back-nav"
								onClick={(event: any) => back(event)}
							>
								<img src={AngleLeftIcon} alt="" />
								Reports
							</Link>
							<ul>
								<li>
									<Link
										to="/reports/overflow"
										className={
											currentSideBar === '/reports/overflow'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() => setCurrentSideBar('/reports/overflow')}
									>
										Overview
									</Link>
								</li>
								{/* <li>
									<Link
										to="/reports/section-progress"
										className={
											currentSideBar === '/reports/section-progress'
												? 'nav-link active'
												: 'nav-link'
										}
										onClick={() =>
											setCurrentSideBar('/reports/section-progress')
										}
									>
										Section Progress
									</Link>
								</li>
								<li>
									<Link to="/" className="nav-link">
										{' '}
										Quizzes{' '}
									</Link>
								</li>
								<li>
									<Link to="/" className="nav-link">
										Login History
									</Link>
								</li>
								<li>
									<Link to="/" className="nav-link">
										Time Spent
									</Link>
								</li>
								<li>
									<Link to="/" className="nav-link">
										Heatmaps
									</Link>
								</li> */}
							</ul>
						</div>
					</li>
				</ul>
				<a className="sidebar-settting">
					<div className="icon-wrap">
						<SettingsIcon />
						<span className="sidebar-link-text">Settings</span>
					</div>
				</a>
			</div>
		</div>
	);
};

export default SideBar;
