import Menu, { MenuProps } from "@material-ui/core/Menu";
import { Theme, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "context/translationContext";

import { FlagES } from "../../assets/images/icons/Flags/FlagES";
import { FlagFR } from "../../assets/images/icons/Flags/FlagFR";
import { FlagGB } from "../../assets/images/icons/Flags/FlagGB";
import { FlagJP } from "../../assets/images/icons/Flags/FlagJP";
import { FlagSE } from "../../assets/images/icons/Flags/FlagSE";
import { FlagUS } from "../../assets/images/icons/Flags/FlagUS";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { SmallArrow } from "../../assets/images/icons/SmallArrow";

// Language Codes
export const LC = {
  ENGB: "enGB",
  ENUS: "enUS",
  ESP: "esp",
  FR: "fr",
  JP: "jp",
  SV: "sv"
};


type FlagSvg = () => JSX.Element;

const flags = new Map<string, FlagSvg>([
  [LC.ENGB, FlagGB],
  [LC.ENUS, FlagUS],
  [LC.FR, FlagFR],
  [LC.SV, FlagSE],
  [LC.JP, FlagJP],
  [LC.ESP, FlagES]
]);

const LANG = new Map<string, string>([
  [LC.ENGB, "English UK"],
  [LC.ENUS, "English US"],
  [LC.FR, "Français"],
  [LC.SV, "Svenska"],
  [LC.JP, "日本語"],
  [LC.ESP, "Español"]
]);

const StyledMenu = withStyles({
  paper: {
    color: "black",
    backgroundColor: "#00000000",
    width: 180,
    padding: 0,
    margin: 0
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    padding: 0,
    margin: "0px 0px 20px 0px",
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      opacity: 0.5
    }
  }
}))(MenuItem);

const StyledListItemIcon = withStyles(() => ({
  root: {
    minWidth: 50
  }
}))(ListItemIcon);

const LanguageButton = withStyles(() => ({
  label: { color: "white", fontSize: "1rem", fontWeight: 700 }
}))(Button);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "left"
  },
  langButton: {
    paddingLeft: 0,
    [theme.breakpoints.down("xs")]: {
      opacity: 0
    },
    [theme.breakpoints.up("md")]: {
      opacity: 1
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 1
    }
  },
  langText: {
    marginLeft: 16,
    marginRight: 8
  }
}));

interface LanguageDropdownProps {
  showText: boolean;
  altStyle: boolean;
  onMenuOpen?(): void;
  onMenuClose?(): void;
}

const LanguageDropdown = ({ showText, altStyle, onMenuOpen, onMenuClose }:
  LanguageDropdownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const { i18n } = useTranslation("ui", { useSuspense: false });
  const classes = useStyles();
  const { state, dispatch } = useTranslation();
  const { selectedLanguage } = state;

  

  const buttonText = () => {
    return showText || anchorEl ? selectedLanguage : "";
  };

  const buttonIcon = (language: string): JSX.Element => {
    console.log(language);
    const flag = flags.get(language);

    if (flag !== undefined) {
      return flag();
    } else {
      return (<FlagGB />);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (onMenuOpen !== undefined) {
      onMenuOpen();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onMenuClose !== undefined) {
      onMenuClose();
    }
  };

  const handleItemClick = (code: string) => {
    dispatch({ type: 'setSelectedLanguage', payload: code})   
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <LanguageButton
        className={classes.langButton}
        variant="text"
        color="primary"
        onClick={handleClick}
      >
        {buttonIcon(selectedLanguage)}
        <div
          className={classes.langText}
          style={{ color: showText ? "black" : "#000" }}
        >
          {buttonText()}
        </div>
        <SmallArrow
          style={{
            marginLeft: showText ? 24 : 0,
            transform: anchorEl ? "rotate(90deg)" : "",
            transition: "transform 0.3s"
          }}
          fill={showText ? "#ff1a68" : "#1c1c1c"}
          fontSize="small"
        />
      </LanguageButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: altStyle ? 70 : 10 }}
      >
        {(selectedLanguage !== LC.ENGB) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.ENGB)}>
            <StyledListItemIcon>
              {buttonIcon(LC.ENGB)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.ENGB) } />
          </StyledMenuItem>}
        {(selectedLanguage !== LC.ENUS) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.ENUS)}>
            <StyledListItemIcon>
              {buttonIcon(LC.ENUS)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.ENUS) } />
          </StyledMenuItem>}
        {(selectedLanguage !== LC.FR) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.FR)}>
            <StyledListItemIcon>
              {buttonIcon(LC.FR)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.FR) } />
          </StyledMenuItem>}
        {(selectedLanguage !== LC.SV) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.SV)}>
            <StyledListItemIcon>
              {buttonIcon(LC.SV)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.SV) } />
          </StyledMenuItem>}
        {(selectedLanguage !== LC.JP) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.JP)}>
            <StyledListItemIcon>
              {buttonIcon(LC.JP)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.JP) } />
          </StyledMenuItem>}
        {(selectedLanguage !== LC.ESP) &&
          <StyledMenuItem onClick={() => handleItemClick(LC.ESP)}>
            <StyledListItemIcon>
              {buttonIcon(LC.ESP)}
            </StyledListItemIcon>
            <ListItemText primary={ LANG.get(LC.ESP) } />
          </StyledMenuItem>}
      </StyledMenu>
    </div>
  );
};

export default LanguageDropdown;