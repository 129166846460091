import {useState} from "react";
import {useForm} from "react-hook-form";
import {success} from "utils/toast";
import {apiBulkImport} from "api/user";
import {Link} from "react-router-dom";

//file

const BulkImportLearner = ({
  isAddLearner,
  setIsAddLearner,
  isBulkImport,
  setIsBulkImport,
}: {
  isAddLearner: boolean;
  setIsAddLearner: (isAddLearner: boolean) => void;
  isBulkImport: boolean;
  setIsBulkImport: (isBulkImport: boolean) => void;
}) => {
  const {handleSubmit} = useForm<{}>();

  const [displayImportFileName, setDisplayImportFileName] = useState("");
  const [importFile, setImportFile] = useState<any>();

  const bulkImportFileUpload = async (e: any) => {
    setDisplayImportFileName(e.target.files[0].name);
    setImportFile(e.target.files[0]);
  };

  const onBulkSubmit = handleSubmit(async (data: any) => {
    const reader = new FileReader();

    reader.readAsDataURL(importFile);
    reader.onload = () => {
      apiBulkImport({
        file: (reader.result as string).slice(
          (reader.result as string).lastIndexOf(",") + 1
        ),
      }).then((responseData: any) => {
        if (responseData.status === "success") {
          success(responseData.message);
          setIsAddLearner(false);
          setIsBulkImport(false);
        }
      });
    };
    reader.onerror = (error) => {
      console.warn("File Read Error: ", error);
    };
  });

  return (
    <div
      className={`tab-pane fade show active`}
      id="import-learner"
      role="tabpanel"
      aria-labelledby="import-learner-tab"
    >
      <form onSubmit={onBulkSubmit} encType="multipart/form-data">
        <div className="modal-import-learner">
          <p>Follow these steps to import learners:</p>
          <p>
            <strong>Step 1:</strong> Download the document{" "}
            <a
              href="https://video-communication.s3.eu-west-1.amazonaws.com/1655457991370-503df165dd0a71d1learnertemplate%20%282%29.csv" rel="noreferrer" target="blank"
            >
              learnertemplate.csv
            </a>
            &nbsp;and analyze the format
          </p>
          <p>
            <strong>Step 2:</strong> Fill the learner details in the document
            format. (Maximum 1000 learners per upload)
          </p>

          <p>
            <strong>Step 3:</strong> After you have filled with the learners,
            Upload your .csv file.
          </p>
          <div className="upload-file-wrap">
            <p>{displayImportFileName}</p>
            <div className="fileUpload">
              <input
                type="file"
                className="upload"
                onChange={(event: any) => bulkImportFileUpload(event)}
                accept="*.csv"
              />
              <span>Browse File</span>
            </div>
          </div>
          <div className="modal-btn-wrap">
            <span
              className="overview-back"
              onClick={() => {
                setIsAddLearner(false);
                setIsBulkImport(false);
              }}
            >
              Let’s do this later
            </span>
            <button type="submit" className="common-btn btn-bg-primary-color">
              Upload
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default BulkImportLearner;
