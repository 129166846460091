const ProgressBar = ({percent}: {percent: number}) => {
  if (percent === 0) {
    return <div className="common-label bg-not-started">Not Started</div>;
  }
  if (percent > 99) {
    return <div className="common-label bg-completed">Completed</div>;
  }
  let totalProgressWidth: any = {
    width: `${percent.toFixed(2)}%`,
  };
  let progress = "progress-bar ";

  percent <= 20
    ? (progress = " progress-bar progress-bar-danger")
    : percent > 20 && percent <= 50
    ? (progress = "progress-bar progress-bar-warning")
    : percent > 50 && percent <= 80
    ? (progress = "progress-bar progress-bar-primary")
    : (progress = "progress-bar progress-bar-success");

  return (
    <div className="custom-progress-wrap">
      <div className="progress-holder">
        <div className="progress-value">{percent.toFixed(2)} %</div>
        <div className="progress common-progress">
          <div
            className={progress}
            role="progressbar"
            style={totalProgressWidth}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
