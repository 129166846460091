import { Fragment, useEffect, useState } from "react";
import { apiGetExperienceSections } from "api/analytics";
import TimeProgressbar from "components/reports/timeProgressbar";
import { useTranslation } from "context/translationContext";

const TimeSpent = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [data, setData] = useState([]);
  const { state } = useTranslation();
  const { translationObject } = state;

  useEffect(() => {
    setisLoading(true);
    apiGetExperienceSections().then((responseData: any) => {
      setisLoading(false);
      if (responseData.status === "success") {
        // let allData: any = [];
        // let sum = responseData.data.reduce((a: any, b: any) => +a + +b.size, 0);
        let allData = responseData.data;
        allData.sort((a: any, b: any) => b.size - a.size);
        console.log(allData);
        allData = allData.map((data: any, i: number) => {
          data.percent = 100 - 12 * i;
          data.bgColor = "rgba(133, 92, 248, " + String(1 - i * 0.12) + ")"
          return data;
        });
        setData(allData);
      }
    });
  }, []);



  return (
    <div className="common-box">
      <div className="reports-overview-wrap">
        <div className="reports-overview-detail">
          <h4>Time Spent</h4>
          <span>Total Time In Sections</span>
        </div>
      </div>
      {data.map((section: any, i: number) => {
        if (i > 7) {
          return ''
        } else {
          return <TimeProgressbar
            key={i}
            bgColor={section.bgColor}
            progressDone={section.percent}
            progressTitle={section.section + "." + section.subSection + " " + translationObject?.enGB.sections['section-title'][section.title]}
          />
        }
      }
      )}
    </div>
  );
};

export default TimeSpent;
