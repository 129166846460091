import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiSignUp, apiGetUser, apiEditUser } from "api/user";
import { success } from "utils/toast";
import { fileUploader } from "utils/fileUploader";
import { removeBlankPropertyFromObject } from "utils/removeBlankPropertyFromObject";

//images
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";
import DeleteProfileImage from "assets/images/common/delete.svg";

const SingleLearner = ({
  isAddLearner,
  setIsAddLearner,
  isBulkImport,
  setIsBulkImport,
  isEditLearner,
  setIsEditLearner,
}: {
  isAddLearner: boolean;
  setIsAddLearner: (isAddLearner: boolean) => void;
  isBulkImport: boolean;
  setIsBulkImport: (isBulkImport: boolean) => void;
  isEditLearner: any;
  setIsEditLearner: (isEditLearner: any) => void;
}) => {
  const [profilePic, setProfilePic] = useState("");

  const fileUpload = async (e: any) => {
    setProfilePic(URL.createObjectURL(e.target.files[0])); //for quick display
    let profileImage = await fileUploader(e.target.files[0]);
    setProfilePic(profileImage);
  };
  type Input = {
    _id?: string;
    createdAt?: string;
    updatedAt?: string;
    first_name: string;
    last_name: string;
    business_title?: string;
    business_stream?: string;
    department?: string;
    line_manager?: string;
    location?: string;
    office_site?: string;
    email?: string;
    password: string;
    profile_picture?: string;
    email_verification_enabled: boolean;
    role: string;
    is_email_verified?: boolean;
    lastLogin?: string;
    id?: string;
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Input>({
    defaultValues: {
      business_title: "",
      business_stream: "",
      department: "",
      line_manager: "",
      location: "United Kingdom",
      office_site: "",
    },
  });

  useEffect(() => {
    if (isEditLearner.length) {
      apiGetUser(isEditLearner[0]).then((responseData: any) => {
        if (responseData.status === "success") {
          let resetData: Input = { ...responseData.user };

          delete resetData._id;
          delete resetData.id;
          delete resetData.createdAt;
          delete resetData.updatedAt;
          if (resetData.is_email_verified) {
            delete resetData?.is_email_verified;
          }
          if (resetData.lastLogin) {
            delete resetData?.lastLogin;
          }

          reset(resetData);

          if (responseData.user.profile_picture) {
            setProfilePic(responseData.user.profile_picture);
          }
        }
      });
    }
  }, []);

  const onSubmit = handleSubmit(async (data: Input) => {
    data.profile_picture = profilePic;

    let filteredData = removeBlankPropertyFromObject(data);

    if (isEditLearner.length) {
      delete filteredData.email;
      apiEditUser(filteredData, isEditLearner[0]).then((responseData: any) => {
        if (responseData.status === "success") {
          success(" User has been updated ");
          setIsEditLearner([]);
          setIsAddLearner(false);
        }
      });
    } else {
      apiSignUp(filteredData).then((responseData: any) => {
        if (responseData.status === "success") {
          success("New User has been added");
          setIsAddLearner(false);
        }
      });
    }
  });

  return (
    <div
      className={`tab-pane fade show active`}
      id="add-learners"
      role="tabpanel"
      aria-labelledby="add-learners-tab"
    >
      <form className="floating-form" onSubmit={onSubmit}>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="First Name"
            {...register("first_name", { required: true })}
          />
          <label htmlFor="floatingInput">First Name</label>
          {errors.first_name?.type === "required" && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>First Name is required</span>
            </div>
          )}
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Last Name"
            {...register("last_name", { required: true })}
          />
          <label htmlFor="floatingInput">Last Name</label>
          {errors.last_name?.type === "required" && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>Last Name is required</span>
            </div>
          )}
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Business Title"
            {...register("business_title")}
          />
          <label htmlFor="floatingInput">Business Title</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Business Stream"
            {...register("business_stream")}
          />
          <label htmlFor="floatingInput">Business Stream</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Department"
            {...register("department")}
          />
          <label htmlFor="floatingInput">Department</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Line Manager"
            {...register("line_manager")}
          />
          <label htmlFor="floatingInput">Line Manager</label>
        </div>
        <div className="mb-3">
          <label className="form-check-label">Location</label>

          <select className="form-select" {...register("location")}>
            <option value="United Kingdom" key="united-kingdom">United Kingdom</option>
            <option value="United States" key="united-states">United States</option>
            <option value="Sweden" key="sweden">Sweden</option>
            <option value="Spain" key="spain">Spain</option>
            <option value="Australia" key="australia">Australia</option>
          </select>
          {/* <label htmlFor="floatingInput">Location</label> */}
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Office Site"
            {...register("office_site")}
          />
          <label htmlFor="floatingInput">Office Site</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="email"
            className="form-control is-invalid"
            placeholder="Email Address"
            disabled={isEditLearner.length === 1 ? true : false}
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: "Not a valid email address format",
              },
            })}
          />
          <label htmlFor="floatingInputInvalid">Email Address</label>
          {errors.email && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>{errors.email.message}</span>
            </div>
          )}
        </div>
        {isEditLearner.length !== 1 ? (
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control is-invalid"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
              })}
            />
            <label htmlFor="floatingInputInvalid">Password</label>
            {errors.password && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.password.message}</span>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="upload-file-wrap mt-0 mb-3">
          {profilePic !== "" ? (
            <div className="upload-img-holder">
              <div className="upload-img-wrap">
                <img src={profilePic} />
              </div>
              <button className="profile-delete-btn">
                <img
                  src={DeleteProfileImage}
                  onClick={() => setProfilePic("")}
                />
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="fileUpload">
            <input
              type="file"
              className="upload"
              onChange={(event: any) => fileUpload(event)}
            />
            <span>Upload Profile Picture</span>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-check-label">Email Verification Enabled</label>
          <select
            className="form-select"
            {...register("email_verification_enabled")}
          >
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
        </div>

        <div className=" mb-3">
          <label className="form-check-label">Role</label>
          <select className="form-select" {...register("role")}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>

        <div className="modal-btn-wrap">
          <span
            className="overview-back"
            onClick={() => {
              setIsAddLearner(false);
              setIsBulkImport(false);
            }}
          >
            Let’s do this later
          </span>
          <button className="common-btn btn-bg-primary-color">
            {isEditLearner.length === 1 ? "Update Learner" : "Add Learner"}
          </button>
        </div>
      </form>
    </div>
  );
};
export default SingleLearner;
