import React from "react";
import {PieChart, Pie, Cell} from "recharts";

const PiechartCircle = ({
  notStarted,
  inProgress,
  completed,
}: {
  notStarted: number;
  inProgress: number;
  completed: number;
}) => {
  const data = [
    {name: "Not Started", totalProgress: notStarted},
    {name: "In Progress", totalProgress: inProgress},
    {name: "Completed", totalProgress: completed},
  ];

  const COLORS = ["#855CF8", "#B085FF", "#503795"];

  return (
    <PieChart width={206} height={206}>
      <Pie data={data} dataKey="totalProgress" outerRadius={80}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default PiechartCircle;
