import { useState, useEffect } from 'react';
const Progressbar = ({
	progressDone,
	progressTitle,
	progressName,
}: {
	progressDone: string;
	progressTitle: string;
	progressName: string;
}) => {
	const [style, setStyle] = useState({});
	useEffect(() => {
		const newStyle = {
			opacity: 1,
			width: `${progressDone}%`,
			title: `${progressName}`,
		};
		setStyle(newStyle);
	}, [progressDone, progressTitle, progressName]);

	return (
		<div className="react-progress react-progress-location" title={progressTitle}>
			<div className="progress-done" style={style}>
			</div>
			<div className="progress-text">
				{progressName}
			</div>
		</div>
	);
};

export default Progressbar;
