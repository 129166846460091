import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import ActiveUsers from "./activeUsers";
import TotalLearnerProgress from "./totalLearnerProgress";
import DailyLogins from "./dailyLogins";
import ExperienceSections from "./experienceSections";
import NewAccounts from "./newAccounts";
import ExperienceLocations from "./experienceLocations";
import TimeSpent from "./timeSpent/timeSpent";
import { apiGetActiveUsers } from "api/analytics";


const Overflow = () => {
  const [totalLearner, setTotalLearner] = useState(0);
  useEffect(() => {
    apiGetActiveUsers().then((responseData: any) => {
      if (responseData.status === "success") {
        setTotalLearner(responseData.data.usersCount);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="main-content container-fluid">
        <div className="common-overview-header">
          <div className="lerners-info">
            <h2>Reports</h2>
            <span>{totalLearner} Learners</span>
          </div>
        </div>
        <div className="overview-table-header">
          <h4>Overview</h4>
        </div>
        <div className="row reports-overview">
          <div className="col-lg-4 col-xl-4 mb-25 d-flex">
            <ExperienceLocations />
          </div>
          <div className="col-lg-4 col-xl-4 mb-25 d-flex">
            <TotalLearnerProgress />
          </div>
          <div className="col-lg-4 col-xl-4 mb-25 d-flex">
            <DailyLogins />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-2 mb-25 d-flex">
            <ActiveUsers />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4 mb-25 d-flex">
            <TimeSpent />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 mb-25 d-flex">
            <ExperienceSections />
          </div>
          <div className="col-md-6 col-lg-4 col-xl-3 mb-25 d-flex">
            <NewAccounts />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Overflow;
