import {Fragment} from "react";
import {useHistory} from "react-router";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";

import BackIcon from "assets/images/login/back-icon.svg";
import ForwardIcon from "assets/images/login/forward-icon.svg";
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";

const ResetPassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{token: string}>();

  const onSubmit = handleSubmit(async (data: {token: string}) => {
    history.push(`/update-password/${data.token}`);
  });

  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form" onSubmit={onSubmit}>
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap">
            <h4>Forgotten Password?</h4>
            <span>
              If the entered address is registered, please check your inbox for
              a password reset token and enter it below:
            </span>
          </div>

          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              placeholder="Reset Token"
              {...register("token", {
                required: "Token is required",
              })}
            />
            <label htmlFor="floatingInput">Reset Token</label>
            {errors.token && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.token.message}</span>
              </div>
            )}
          </div>
          <div className="reset-token-text mb-4">
            The email may take a few minutes to arrive - please also check your
            spam folder.
          </div>
          <div className="login-footer">
            <Link to="/forget-password" className="common-btn btn-border-color">
              <img className="back-icon" src={BackIcon} alt="Token icon" />
              Resend Token
            </Link>
            <button className="common-btn btn-bg-secondary-color">
              Continue
              <img
                className="token-icon"
                src={ForwardIcon}
                alt="forward icon"
              />
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
