import { Fragment, useEffect, useState } from "react";
import Loading from "components/Loader";
import BubbleChart from "components/reports/bubblechart";
import { apiGetExperienceSections } from "api/analytics";
import { Types } from "components/reports/bubblechart/types";

const ExperienceSections = () => {
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const [counts, setCounts] = useState([]);
  const [isLoading, setisLoading] = useState<boolean>(false);

  const [data, setData] = useState<Types.Data[]>([]);

  const selectedKeyHandler = (key: string) => {
    // eslint-disable-next-line no-alert
    console.log(key);
  };
  useEffect(() => {
    setisLoading(true);
    apiGetExperienceSections().then((responseData: any) => {
      setisLoading(false);
      if (responseData.status === "success") {
        let allData: any = [];
        let sum = responseData.data.reduce((a: any, b: any) => +a + +b.size, 0);

        responseData.data.map((a: any) => {
          let colors = [
            { textFillColor: "#ffffff", fillColor: "#503795" },
            { textFillColor: "#503795", fillColor: "#EBE5FE" },
            { textFillColor: "#ffffff", fillColor: "#855CF8" },
          ];
          const random = Math.floor(Math.random() * colors.length);
          let initialData: any = colors[random];
          initialData.id = a._id;
          initialData.name = parseInt(a.section);
          initialData.size = (a.size / sum) * 100 < 10 ? 10 : (a.size / sum) * 100;
          if (allData.hasOwnProperty((initialData.name - 1))) {
            allData[initialData.name - 1].size = allData[initialData.name - 1].size + initialData.size;
          } else {
            allData[initialData.name - 1] = initialData;
          }
        });
        setData(allData);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="common-box">

        <div className="reports-overview-wrap">
          <div className="reports-overview-detail">
            <h4>Experience Sections</h4>
            <span>Most Viewed Sections</span>
          </div>
          <div className="reports-overview-icon">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.8376 8.1C5.28064 8.1 4.7465 7.87875 4.35267 7.48492C3.95885 7.0911 3.7376 6.55695 3.7376 6C3.7376 5.44305 3.95885 4.9089 4.35267 4.51508C4.7465 4.12125 5.28064 3.9 5.8376 3.9C6.39455 3.9 6.9287 4.12125 7.32252 4.51508C7.71635 4.9089 7.9376 5.44305 7.9376 6C7.9376 6.55695 7.71635 7.0911 7.32252 7.48492C6.9287 7.87875 6.39455 8.1 5.8376 8.1ZM10.2956 6.582C10.3196 6.39 10.3376 6.198 10.3376 6C10.3376 5.802 10.3196 5.604 10.2956 5.4L11.5616 4.422C11.6756 4.332 11.7056 4.17 11.6336 4.038L10.4336 1.962C10.3616 1.83 10.1996 1.776 10.0676 1.83L8.5736 2.43C8.2616 2.196 7.9376 1.992 7.5596 1.842L7.3376 0.252C7.3136 0.108 7.1876 0 7.0376 0H4.6376C4.4876 0 4.3616 0.108 4.3376 0.252L4.1156 1.842C3.7376 1.992 3.4136 2.196 3.1016 2.43L1.6076 1.83C1.4756 1.776 1.3136 1.83 1.2416 1.962L0.0415989 4.038C-0.0364011 4.17 -0.000401017 4.332 0.113599 4.422L1.3796 5.4C1.3556 5.604 1.3376 5.802 1.3376 6C1.3376 6.198 1.3556 6.39 1.3796 6.582L0.113599 7.578C-0.000401017 7.668 -0.0364011 7.83 0.0415989 7.962L1.2416 10.038C1.3136 10.17 1.4756 10.218 1.6076 10.17L3.1016 9.564C3.4136 9.804 3.7376 10.008 4.1156 10.158L4.3376 11.748C4.3616 11.892 4.4876 12 4.6376 12H7.0376C7.1876 12 7.3136 11.892 7.3376 11.748L7.5596 10.158C7.9376 10.002 8.2616 9.804 8.5736 9.564L10.0676 10.17C10.1996 10.218 10.3616 10.17 10.4336 10.038L11.6336 7.962C11.7056 7.83 11.6756 7.668 11.5616 7.578L10.2956 6.582Z"
                fill="#94A3B3"
              />
            </svg>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <BubbleChart
              bubblesData={data}
              width={300}
              height={240}
              backgroundColor="#fff"
              textFillColor="#ffffff"
              minValue={0}
              maxValue={150}
              selectedCircle={selectedKeyHandler}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ExperienceSections;
