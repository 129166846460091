import { Fragment, useEffect, useState } from "react";
import Loading from "components/Loader";
import AreaLineChart from "components/reports/areaLineChart";
import { apiGetNewAccounts } from "api/analytics";

const NewAccounts = () => {
  const [today, setToday] = useState(0);
  const [dates, setDates] = useState([]);
  const [counts, setCounts] = useState([]);
  const [isLoading, setisLoading] = useState<boolean>(false);

  useEffect(() => {
    setisLoading(true);
    apiGetNewAccounts().then((responseData: any) => {
      setisLoading(false);
      if (responseData.status === "success") {
        setDates(responseData.data.map((a: any) => a.date));
        setCounts(responseData.data.map((a: any) => a.count));
        setToday(responseData.data[0].count);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="common-box">
        <div className="reports-overview-wrap">
          <div className="reports-overview-detail">
            <h4>New Accounts</h4>
            <span>Daily Users Onboarded</span>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <div className="area-chart-wrap">
              <div className="area-line-chart">
                <AreaLineChart dates={dates} counts={counts} />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default NewAccounts;
