import {Fragment, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {apiSignIn} from "api/user";
import {error} from "utils/toast";
import {useHistory} from "react-router";
import jwt_decode from "jwt-decode";
import { useAuth } from "context/authContext";
//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";
import ShowPassIcon from "assets/images/login/show-password-icon.svg";
import HidePassIcon from "assets/images/login/hide-password-icon.svg";
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";

const Login = () => {
  const { state, dispatch } = useAuth()
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: {},
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token !== null) {
      interface Token {
        name: string | null;
        exp: number;
      }
      let decodedToken = jwt_decode<Token>(token || "");
      if (decodedToken.exp > Date.now() / 1000) {
        history.push("/dashboard");
      }
    }
  }, [history]);

  const onSubmit = handleSubmit((data: {email: string}) => {
    apiSignIn(data).then((responseData: any) => {
      if (
        responseData.status === "success" &&
        responseData.user?.role === "admin"
      ) {
        dispatch({type: 'setUser', payload: responseData.user})
        localStorage.setItem("token", responseData.token);
        history.push("/dashboard");
      } else if (responseData.status === "success") {
        error("You are not allowed to login");
      }
    });
  });

  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form" onSubmit={onSubmit}>
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap">
            <h4>Content Management System</h4>
            <span>Admin Access Only</span>
          </div>

          <div className="form-floating mb-4">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: "Not a valid email address format",
                },
              })}
            />
            <label htmlFor="floatingInput">Email Address</label>
            {errors.email?.type === "required" && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.email.message}</span>
              </div>
            )}
          </div>

          <div className="form-floating mb-4">
            <input
              type={isPasswordShown ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
              })}
            />
            <label htmlFor="floatingInput">Password</label>
            <div className="login-show-password">
              <img
                src={isPasswordShown ? ShowPassIcon : HidePassIcon}
                alt="icon"
                onClick={() => setIsPasswordShown((prevState) => !prevState)}
              />
            </div>
            {errors.password && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.password.message}</span>
              </div>
            )}
          </div>
          <div className="login-footer">
            <Link className="forget-password" to="/forget-password">
              Forgot Password
            </Link>
            <button className="common-btn btn-bg-secondary-color">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Login;
