import { AxiosPromise, AxiosResponse } from 'axios';

import { client } from 'api/config';
import { ApiRequestUrl, ApiErrorStatusCode } from 'api/constants';
import { error } from "utils/toast";
export interface IRequestError {
  status: number,
  text: string,
}

const parseResult = (result: AxiosResponse): AxiosResponse['data'] => result && result.data;

const onError = (Response:any): IRequestError => {

  let response =Response?.response?.data

  if(typeof response.error === 'object'){
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  }else{
    error(response.error)
    
  }
  
  switch (response.statusCode) {
    case ApiErrorStatusCode.BAD_REQUEST:
      return {
        status: response.statusCode,
        text: response.message,
      };
    case ApiErrorStatusCode.UNAUTHORIZEDS:
      return {
        status: response.statusCode,
        text: 'Authorisation error',
      };
    case ApiErrorStatusCode.NOT_FOUND:
      return {
        status: response.statusCode,
        text: 'Not found',
      };
    case response.statusCode >= ApiErrorStatusCode.INTERNAL_STATUS_ERROR:
      return {
        status: response.statusCode,
        text: 'It seems something went wrong. Please, try later.',
      };
    default:
      return {
        status: response.statusCode,
        text: response.message,
      };
  }
};

export const apiGetAllSections = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_ALL_SECTION_PROGRESS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetContent = (data = {}): AxiosPromise => (
		client({
		  data,
		  method: 'POST',
		  url: `${ApiRequestUrl.GET_CONTENT_OVERVIEW}`,
		})
		  .then(parseResult)
		  .catch(onError)
);

export const apiGetSectionGroupById = (sectionGroupId: String): AxiosPromise => (
  client({
    method: 'GET',
    url: `/admin/section-group/get-one/${sectionGroupId}`,
  })
    .then(parseResult)
    .catch(onError)
);

