/* eslint-disable max-len */

import { SvgIcon } from "@material-ui/core/";

export function FlagES(): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <path
                fill="#FFDA44"
                d="M0 16c0 1.957.352 3.832.995 5.565L16 22.956l15.005-1.39C31.648 19.831 32 17.956 32 16c0-1.957-.352-3.832-.995-5.565L16 9.043.995 10.435C.352 12.168 0 14.043 0 16z"
                transform="translate(-24.000000, -349.000000) translate(0.000000, 107.000000) translate(24.000000, 242.000000)" />
              <g fill="#D80027">
                <path d="M30.01 10.435C27.749 4.342 21.884 0 15.005 0 8.125 0 2.26 4.342 0 10.435h30.01zM0 21.565C2.26 27.658 8.125 32 15.005 32c6.88 0 12.744-4.342 15.005-10.435H0z"
                  transform="translate(-24.000000, -349.000000) translate(0.000000, 107.000000) translate(24.000000, 242.000000) translate(0.995188, 0.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
