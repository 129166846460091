import { Fragment, useEffect, useState } from "react";
import Loading from "components/Loader";
import CircleChart from "components/reports/circlechart";
import { apiGetActiveUsers } from "api/analytics";

const ActiveUsers = () => {
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setisLoading] = useState<boolean>(false);

  useEffect(() => {
    setisLoading(true);
    apiGetActiveUsers().then((responseData: any) => {
      setisLoading(false);
      if (responseData.status === "success") {
        setPercentage(
          parseFloat(
            (
              (responseData.data.activeUsersCount /
                responseData.data.usersCount) *
              100
            ).toFixed(2)
          )
        );
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="common-box">
        <div className="reports-overview-wrap">
          <div className="reports-overview-detail">
            <h4>Active Users</h4>
            <span>Users Online Today</span>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            <CircleChart strokeWidth={8} percentage={percentage} />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default ActiveUsers;
