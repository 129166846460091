import { Fragment } from 'react';
import { Link } from "react-router-dom";

//images
import FiltersIcon from 'assets/images/common/filters-icon.svg';
import LongArrowUpIcon from 'assets/images/common/long-arrow-up-icon.svg';
import AngleLeftBlackIcon from "assets/images/common/angle-left-black-icon.svg";

const SectionProgress = () => {
	return (
		<Fragment>
			<div className="main-content container-fluid">
				<div className="common-overview-header">
					<div className="lerners-info">
						<h2>Reports</h2>
						<span>2246 Learners</span>
					</div>
					<div className="button-wrap">
						<button className="common-btn btn-bg-green-color ml-20">
							Download CSV
						</button>
					</div>
				</div>

				<div className="overview-table-header">
					<h4>Section Progress</h4>
					<Link className="overview-back" to="/reports/overflow">
            <img src={AngleLeftBlackIcon} alt="icon" />
            Back
          </Link>
				</div>

				<div className="overview-table-block">
					<div className="overview-tab-holder">
						<ul
							className="nav nav-tabs common-nav-tabs"
							id="overviewTab"
							role="tablist"
						>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="all-locations-tab"
									data-bs-toggle="tab"
									data-bs-target="#all-locations"
									type="button"
									role="tab"
									aria-controls="all-locations"
									aria-selected="true"
								>
									All Locations
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="uk-location-tab"
									data-bs-toggle="tab"
									data-bs-target="#uk-location"
									type="button"
									role="tab"
									aria-controls="uk-location"
									aria-selected="false"
								>
									United Kingdom
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="us-location-tab"
									data-bs-toggle="tab"
									data-bs-target="#us-location"
									type="button"
									role="tab"
									aria-controls="us-location"
									aria-selected="false"
								>
									United States
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="aus-location-tab"
									data-bs-toggle="tab"
									data-bs-target="#aus-location"
									type="button"
									role="tab"
									aria-controls="aus-location"
									aria-selected="false"
								>
									Australia
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="swe-location-tab"
									data-bs-toggle="tab"
									data-bs-target="#swe-location"
									type="button"
									role="tab"
									aria-controls="swe-location"
									aria-selected="false"
								>
									Sweden
								</button>
							</li>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link"
									id="jp-location-tab"
									data-bs-toggle="tab"
									data-bs-target="#jp-location"
									type="button"
									role="tab"
									aria-controls="jp-location"
									aria-selected="false"
								>
									Japan
								</button>
							</li>
						</ul>
						<a className="show-filters" href="javascript:void(0);">
							<img src={FiltersIcon} alt="icon" />
							Show Filters
						</a>
					</div>
					<div className="tab-content" id="overviewTabContent">
						<div
							className="tab-pane fade show active"
							id="all-locations"
							role="tabpanel"
							aria-labelledby="all-locations-tab"
						>
							<div className="table-responsive overview-table-list section-progress-table">
								<table className="table">
									<thead>
										<tr>
											<th scope="col">
												<div className="thead-text">Section Title</div>
											</th>
											<th scope="col">
												<div className="thead-text">
													Section Progress
													<img src={LongArrowUpIcon} alt="icon" />
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>1.1 Welcome to Ocado</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">90%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-success"
																role="progressbar"
																style={{ width: '90%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>1.2 Introduction from Tim Steiner</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">90%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-success"
																role="progressbar"
																style={{ width: '90%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>1.3 Our Key History Milestones Part 1</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">58%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-primary"
																role="progressbar"
																style={{ width: '58%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>1.4 Mini Quiz 1</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">23%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-warning"
																role="progressbar"
																style={{ width: '23%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>1.5 Our Key History Milestones Part 2</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">90%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-success"
																role="progressbar"
																style={{ width: '90%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>2.1 Ocado Smart Platform in Action</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">90%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-success"
																role="progressbar"
																style={{ width: '90%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div className="info-wrap view-learner-text">
													<span>2.2 Mini Quiz 2</span>
												</div>
											</td>
											<td>
												<div className="custom-progress-wrap">
													<div className="progress-holder">
														<div className="progress-value">90%</div>
														<div className="progress common-progress">
															<div
																className="progress-bar progress-bar-success"
																role="progressbar"
																style={{ width: '90%' }}
																aria-valuenow={Number('25')}
																aria-valuemin={Number('0')}
																aria-valuemax={Number('100')}
															></div>
														</div>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="uk-location"
							role="tabpanel"
							aria-labelledby="uk-location-tab"
						>
							United Kingdom
						</div>
						<div
							className="tab-pane fade"
							id="us-location"
							role="tabpanel"
							aria-labelledby="us-location-tab"
						>
							United States
						</div>
						<div
							className="tab-pane fade"
							id="aus-location"
							role="tabpanel"
							aria-labelledby="aus-location-tab"
						>
							Australia
						</div>
						<div
							className="tab-pane fade"
							id="swe-location"
							role="tabpanel"
							aria-labelledby="swe-location-tab"
						>
							Sweden
						</div>
						<div
							className="tab-pane fade"
							id="jp-location"
							role="tabpanel"
							aria-labelledby="jp-location-tab"
						>
							Japan
						</div>
					</div>
				</div>

				<div className="overview-table-footer fixed-bottom">
					<div className="row-page-wrap">
						<h4>Rows per page</h4>
						<select className="form-select" aria-label="Default select example">
							<option selected>7</option>
							<option value="1">5</option>
							<option value="2">20</option>
							<option value="3">100</option>
						</select>
					</div>
					<div className="custom-pagination">
						<div className="pagination-wrap">
							<a href="javascript:void();">
								<svg
									width="8"
									height="16"
									viewBox="0 0 8 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.99707 1.59033L1.99707 8.12158L6.99707 14.6538"
										stroke="#6F75BC"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</a>
							<input type="text" className="form-control" value="1" />
							<a href="javascript:void();">
								<svg
									width="8"
									height="15"
									viewBox="0 0 8 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.5 13.9258L6.25488 7.71484L1.5 1.50098"
										stroke="#6F75BC"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</a>
						</div>
						<span className="showing-page">of 10</span>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default SectionProgress;
