import {fileUploader} from "utils/fileUploader";


const BrowseFile = ({title, content, handleChange, value, disabled} : {title: string, content: any, handleChange: Function, value:string, disabled: boolean}) => {
	const fileUpload = async (e: any) => {
		let profileImage = await fileUploader(e.target.files[0]);
		handleChange({name: content.translation, value: profileImage});
	  };

	return (
		<div className="upload-file-wrap form-floating browse-file-floating mt-0 empty-form-control-value">
				<a className="form-control url-text" target="blank" href={value}>{value}</a>
				<div className="fileUpload">
					<input
						type="file"
						className="upload"
						name={content.translation}
						onChange={(event: any) => fileUpload(event)}
						accept="*.csv"
						disabled={disabled}
						placeholder={title}
					/>
					<span>Browse File</span>
				</div>
				<label htmlFor="floatingInput">{title}</label>
		</div>
	);
};

export default BrowseFile;
