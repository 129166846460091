import { ReactNode, useEffect } from 'react';
import { createContext, useReducer, useContext } from "react";
import { apiGetAllTranslations, apiUpdateTranslations } from 'api/translation'
const defaultState = { translationObject: null, selectedLanguage: 'enGB' };

export type ActionKind = 'setTranslationObject' | 'removeTranslationObject' | 'updateTranslationObject' | 'setSelectedLanguage';

interface Action {
  type: ActionKind;
  payload?: any
}
export type Dispatch = (action: Action) => void;
interface State {
    translationObject: any | null,
    selectedLanguage: string
};
interface ContextType {
  state: State, 
  dispatch: Dispatch,
  updateTranslations: ({data}: any) => Promise<void>;
}
const TranslationContext = createContext<ContextType | undefined>(undefined);

function translationReducer(state: State, action: Action) {
  switch(action.type) {
    case 'setTranslationObject':
      return {
        ...state,
        translationObject: action.payload || null
      };
    case 'updateTranslationObject':
      return {
        ...state,
        translationObject: action.payload || null
      };
    case 'removeTranslationObject':
      return { 
        ...state,
        translationObject: null
      }
    case 'setSelectedLanguage':
      return {
        ...state,
        selectedLanguage: action.payload
      }
  }
}


function TranslationProvider({children} : {children: ReactNode}) {
  const [ state, dispatch ] = useReducer(translationReducer, defaultState)

  useEffect(() => {
      async function getTrans() {
        const res = await apiGetAllTranslations()
        dispatch({type: 'setTranslationObject', payload: res?.data?.translation});
      }
      
      getTrans();
  }, [])
  
  async function updateTranslations ({ data } : any) {
      const tData = {
        "translations": [data]
      };
      await apiUpdateTranslations({data: tData});
      // await apiGetAllTranslations();
  }


  return (
  <TranslationContext.Provider value={{state, dispatch, updateTranslations}}>
    {children}
  </TranslationContext.Provider>
  );
}

function useTranslation() {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error(`useTranslation must be used within a TranslationProvider`);
  }

  return context;
}

export { TranslationProvider, useTranslation };
    function getTrans() {
        throw new Error('Function not implemented.');
    }

