import {Fragment, useState} from "react";
import AddLearner from "screens/learners/addLearner";
import ListLearner from "screens/learners/listLearner";

const Learners = () => {
  const [isAddLearner, setIsAddLearner] = useState<boolean>(false);
  const [isEditLearner, setIsEditLearner] = useState<any>([]);
  const [isBulkImport, setIsBulkImport] = useState<boolean>(true);

  return (
    <Fragment>
      <ListLearner
        isAddLearner={isAddLearner}
        setIsAddLearner={setIsAddLearner}
        isBulkImport={isBulkImport}
        setIsBulkImport={setIsBulkImport}
        isEditLearner={isEditLearner}
        setIsEditLearner={setIsEditLearner}
      />

      {isAddLearner || isEditLearner.length === 1 ? (
        <AddLearner
          isAddLearner={isAddLearner}
          setIsAddLearner={setIsAddLearner}
          isBulkImport={isBulkImport}
          setIsBulkImport={setIsBulkImport}
          isEditLearner={isEditLearner}
          setIsEditLearner={setIsEditLearner}
        />
      ) : null}
    </Fragment>
  );
};

export default Learners;
