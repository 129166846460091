
const SwitchToggle = ({ isOn, handleToggle, onColor } : { isOn:boolean, handleToggle: () => void, onColor:string } ) => {
  return (
    <div className="react-switch-wrap">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={ {background: isOn ? onColor:''} }
        className="react-switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};
export default SwitchToggle;
