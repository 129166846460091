import { Fragment } from 'react';
import Moment from 'react-moment';

const ViewLogModal = ({ log, setActiveLogForModal }: any) => {
	return (
		<Fragment>
			<div
				className="modal fade show view-log-modal"
				id="viewLogModal"
				aria-labelledby="viewLogModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="viewLogModalLabel">
								Your View Log
							</h5>
							<button
								type="button"
								className="btn-close"
								aria-label="Close"
								onClick={() => setActiveLogForModal(null)}
							></button>
						</div>
						<div className="modal-body">
							<ul className="view-log-list">
								<li>
									Agent:
									{log.agent === 'system' ? (
										<span>System</span>
									) : (
										<span>{log.actor.first_name} {log.actor.last_name}</span>
									)}
								</li>
								<li>
									Password Changed At:{''}
									<span><Moment format="DD/MM/YY HH:mm">{log.createdAt}</Moment></span>
								</li>
								<li>
										New password:<span>{atob(log.log.password)}</span>
								</li>
									{
										log.agent !== 'system' && (
											<li>
												Old password:<span>{atob(log.log.old_password)}</span>
											</li>
										)
									}
							</ul>
						</div>
						<div className="modal-footer">
							<button
								onClick={() => setActiveLogForModal(null)}
								type="button"
								className="common-btn btn-bg-green-color"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ViewLogModal;
