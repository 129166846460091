import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContentOverviewList from './contentOverviewList';
import Pagination  from 'components/pagination'
import { apiGetContent } from 'api/section';

//images
import AngleLeftBlackIcon from 'assets/images/common/angle-left-black-icon.svg';

const ContentOverview = () => {
	const [sectionGroups, setSectionGroups] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(8);
	const [totalCount, setTotalCount] = useState(10);
	useEffect(() => {
		(async function getContent() {
			const res = await apiGetContent({ skip: currentPage, limit: perPage });
			if(res) {
				setSectionGroups(res.data?.sectionGroups);
				setTotalCount(res.data?.meta?.itemCount)
			}
		}) ();
	}, [currentPage, perPage]);
	return (
		<Fragment>
			<div className="main-content container-fluid">
				<div className="common-overview-header">
					<div className="lerners-info">
						<h2>Overview</h2>
						{/* <span>33 Scenes</span> */}
					</div>
					<div className="button-wrap">
						{/* <button className="common-btn btn-bg-green-color ml-20">
							Add New Section
						</button> */}
					</div>
				</div>

				<div className="overview-table-header">
					<h4>Content Overview</h4>
					<Link className="overview-back" to="/dashboard">
						<img src={AngleLeftBlackIcon} alt="icon" />
						Back
					</Link>
				</div>

				<div className="row mb-20">
					<ContentOverviewList sectionGroups={sectionGroups} />
				</div>
				<Pagination currentPage={currentPage} 
					totalCount={totalCount} 
					perPage={perPage} 
					onPageChange={(currentPage: number)=> setCurrentPage(currentPage)}
					onPerPageChange={(perPage: number)=> setPerPage(perPage)} />
			</div>
		</Fragment>
	);
};

export default ContentOverview;
