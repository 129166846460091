import {Fragment, useState} from "react";
import {success} from "utils/toast";
import {useForm} from "react-hook-form";
import {apiResetPassword} from "api/auth";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";

import ForwardIcon from "assets/images/login/forward-icon.svg";
import ShowPassIcon from "assets/images/login/show-password-icon.svg";
import HidePassIcon from "assets/images/login/hide-password-icon.svg";
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";

const UpdatePassword = () => {
  const history = useHistory();
  const [isNewPasswordShown, setIsNewPasswordShown] = useState<boolean>(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] =
    useState<boolean>(false);

  interface updatePassword {
    password: string;
    confirm_password: string;
    token: string;
    userId: string;
  }
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm<updatePassword>();

  type viewParams = {
    userId: string;
    token: string;
  };
  const {userId, token} = useParams<viewParams>();

  const onSubmit = handleSubmit((data: updatePassword) => {
    data.userId = userId;
    data.token = token;

    apiResetPassword(data).then((responseData: any) => {
      if (responseData.status === "success") {
        success(responseData.data);
        history.push("/success-password");
      }
    });
  });
  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form" onSubmit={onSubmit}>
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap">
            <h4>Forgotten Password?</h4>
            <span>Please enter your new password below:</span>
          </div>

          <div className="form-floating mb-4">
            <input
              type={isNewPasswordShown ? "text" : "password"}
              className="form-control"
              placeholder="New Password"
              {...register("password", {
                required: "New Password is required",
                pattern: {
                  value: /^(?=.*[!@#$^*-])(?=.*[0-9])(?=.*[A-Z]).{8,20}$/i,
                  message: `Your password must be a minimum of 8 characters, including an upper
                  & lower case letter, number and special character including at
                  least one of the following: ! @ # $ ^ * -`,
                },
              })}
            />
            <label htmlFor="floatingInput">New Password</label>
            <div className="login-show-password">
              <img
                src={isNewPasswordShown ? ShowPassIcon : HidePassIcon}
                alt="icon"
                onClick={() => setIsNewPasswordShown((prevState) => !prevState)}
              />
            </div>
            {errors.password && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.password.message}</span>
              </div>
            )}
          </div>
          <div className="form-floating mb-4">
            <input
              type={isConfirmPasswordShown ? "text" : "password"}
              className="form-control"
              placeholder="Confirm New Password"
              {...register("confirm_password", {
                required: "Confirm New Password is required",
                validate: (value) =>
                  value === watch("password") || "Passwords don't match.",
              })}
            />
            <label htmlFor="floatingInput">Confirm New Password</label>
            <div className="login-show-password">
              <img
                src={isConfirmPasswordShown ? ShowPassIcon : HidePassIcon}
                alt="icon"
                onClick={() =>
                  setIsConfirmPasswordShown((prevState) => !prevState)
                }
              />
            </div>
            {errors.confirm_password && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.confirm_password.message}</span>
              </div>
            )}
          </div>

          <div className="login-footer justify-content-end">
            <button className="common-btn btn-bg-secondary-color">
              Update
              <img
                className="token-icon"
                src={ForwardIcon}
                alt="forward icon"
              />
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
