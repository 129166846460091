import { SvgIcon } from "@material-ui/core";

export function FlagJP(): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g
              transform="translate(-24.0, -296.0) translate(0.0, 107.0) translate(24.0, 189.0)">
              <circle cx="16" cy="16" r="16" fill="#F0F0F0" />
              <circle cx="16" cy="16" r="6.957" fill="#D80027" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
