import {Fragment} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import {apiForgotPassword} from "api/auth";
import {success} from "utils/toast";
import {useHistory} from "react-router";

//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";
import TokenIcon from "assets/images/login/share-icon.svg";
import BackIcon from "assets/images/login/back-icon.svg";
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";

const ForgetPassword = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<{email: string}>();

  const onSubmit = handleSubmit(async (data: {email: string}) => {
    apiForgotPassword(data).then((responseData: any) => {
      if (responseData.status === "success") {
        success("Please check your Email to update your password");
        history.push("/login");
      }
    });
  });
  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form" onSubmit={onSubmit}>
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap">
            <h4>Forgotten Password?</h4>
            <span>
              Please confirm that this is your email address, and we’ll send you
              a password reset token.
            </span>
          </div>

          <div className="form-floating mb-4">
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: "Not a valid email address format",
                },
              })}
            />
            <label htmlFor="floatingInput">Email Address</label>
            {errors.email && (
              <div className="error-msg">
                <img src={NotAValidIcon} alt="icon" />
                <span>{errors.email.message}</span>
              </div>
            )}
          </div>
          <div className="login-footer">
            <Link to="/" className="common-btn btn-border-color">
              <img className="back-icon" src={BackIcon} alt="Back Icon" />
              Back
            </Link>
            <button className="common-btn btn-bg-secondary-color">
              Send Token
              <img className="token-icon" src={TokenIcon} alt="Token icon" />
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ForgetPassword;
