import {useState} from "react";
import {apiCentralPasswordUpdate} from "api/user";
import {success} from "utils/toast";
import {useForm} from "react-hook-form";
//images
import ShowPassIcon from "assets/images/login/show-password-icon.svg";
import HidePassIcon from "assets/images/login/hide-password-icon.svg";
import NotAValidIcon from "assets/images/common/not-valid-icon.svg";

const UpdateCentralPassword = () => {
  const [isNewPasswordShown, setIsNewPasswordShown] = useState<boolean>(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: {errors},
  } = useForm<{
    old_password: string;
    password: string;
    password_confirmation: string;
  }>({
    defaultValues: {},
  });

  interface LogsData {
    actor: {
      first_name: string;
      last_name: string;
      _id: string;
    };
    createdAt: string;
  }
  const onSubmit = () =>  handleSubmit((data: any) => {
    apiCentralPasswordUpdate(data).then((responseData: any) => {
      if (responseData.status === "success") {
        success(responseData.data);
        reset();
      }
    });
  });

  return (
    <div className="common-box">
      <form className="floating-form update-password-form" onSubmit={onSubmit}>
        <label className="form-custom-label">Current Password</label>
        <div className="form-floating mb-4">
          <input
            type="text"
            className="form-control"
            placeholder="Current Password"
            {...register("old_password", {
              required: "Current Password is required",
            })}
          />
          <label htmlFor="floatingInput">Current Password</label>
          {errors.old_password && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>{errors.old_password.message}</span>
            </div>
          )}
        </div>
        <label className="form-custom-label">Change Password</label>
        <div className="form-floating mb-4">
          <input
            type={isNewPasswordShown ? "text" : "password"}
            className="form-control"
            placeholder="New Password"
            {...register("password", {
              required: "New Password is required",
              pattern: {
                value: /^(?=.*[!@#$^*-])(?=.*[0-9])(?=.*[A-Z]).{8,20}$/i,
                message: `Your password must be a minimum of 8 characters, including an upper
                & lower case letter, number and special character including at
                least one of the following: ! @ # $ ^ * -`,
              },
            })}
          />
          <label htmlFor="floatingInput">New Password</label>
          <div className="login-show-password">
            <img
              src={isNewPasswordShown ? ShowPassIcon : HidePassIcon}
              alt="icon"
              onClick={() => setIsNewPasswordShown((prevState) => !prevState)}
            />
          </div>
          {errors.password && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>{errors.password.message}</span>
            </div>
          )}
        </div>
        <div className="form-floating mb-4">
          <input
            type={isConfirmPasswordShown ? "text" : "password"}
            className="form-control"
            placeholder="Confirm New Password"
            {...register("password_confirmation", {
              required: "Confirm New Password is required",
              validate: (value) =>
                value === watch("password") || "Passwords don't match.",
            })}
          />
          <label htmlFor="floatingInput">Confirm New Password</label>
          <div className="login-show-password">
            <img
              src={isConfirmPasswordShown ? ShowPassIcon : HidePassIcon}
              alt="icon"
              onClick={() =>
                setIsConfirmPasswordShown((prevState) => !prevState)
              }
            />
          </div>
          {errors.password_confirmation && (
            <div className="error-msg">
              <img src={NotAValidIcon} alt="icon" />
              <span>{errors.password_confirmation.message}</span>
            </div>
          )}
        </div>
        <div className="update-password-wrap">
          <button className="common-btn btn-bg-green-color">
            Update Password
          </button>
        </div>
      </form>
    </div>
  );
};
export default UpdateCentralPassword;
