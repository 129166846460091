import { Fragment, useState, useEffect, FormEvent } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "context/translationContext";
import { success } from "utils/toast";

import VideoLink from "./videoLink";
import IntroSlidePreview from "components/introSlidePreview";
import { apiGetSectionGroupById } from "api/section";
import Loading from "components/Loader";
import BrowseFile from "components/browseFile";
import FloatingDescription from "./floatingDescription";
import LanguageDropdown from "components/countryDropdown/LanguageDropdown";
import Pagination from "components/pagination";

//images
import AngleLeftBlackIcon from "assets/images/common/angle-left-black-icon.svg";
import EditBlackIcon from "assets/images/common/edit-black-icon.svg";
import EditCutIcon from "assets/images/common/pencil-cut.svg";

var _ = require("lodash");

const EditSceneContent = () => {
  const { state, updateTranslations } = useTranslation();
  const { translationObject, selectedLanguage } = state;

  const [sectionGroup, setSectionGroup] = useState<any>({});
  const [activeScene, setActiveScene] = useState<any>(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [sectionGroupTitle, setSectionGroupTitle] = useState<string>("");
  const [inputForm, setInputForm] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  type viewParams = {
    sectionId: string;
  };
  const { sectionId } = useParams<viewParams>();
 
  const handleSave = async (index: number) => {
    const sceneToSave = activeScene[currentPage - 1][index];
    const data: any = {};
    sceneToSave?.content?.length &&
      sceneToSave.content.map((content: any, index: number) => {
        const key = `${selectedLanguage}.${content.translation}`;
        data[key] = inputForm[content.translation] ?? getInputValues(content);
      });
    sceneToSave?.options?.length &&
      sceneToSave.options.map((option: any, index: number) => {
        option.content.map((c: any) => {
          const key = `${selectedLanguage}.${c.translation}`;
          data[key] = inputForm[c.translation] ?? getInputValues(c);
        });
      });
    setIsSaving(true);
    await updateTranslations({ data });
    success("Success");
    setIsSaving(false);
  };
  const handleUpdate = async () => {
    const data = {
      [`${selectedLanguage}.sections.` + sectionGroup.titleID]: sectionGroupTitle,
    };
    await updateTranslations({ data });
    success("Success");
    setEdit(false);
  };
  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    const { name } = e.target as HTMLInputElement;
    const { value } = e.target as HTMLInputElement;

    setInputForm({ ...inputForm, [name]: value });
  };
  const handleChangeUpload = ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => {
    setInputForm({ ...inputForm, [name]: value });
  };

  const handleActiveScene = (subSection: any) => {
    setActiveScene(subSection.scenes);
    setCurrentPage(1);
    setActiveSectionIndex(subSection.index);
  };

  const getInputValues = (content: any) => {
    if (translationObject && translationObject[selectedLanguage]) {
      const text = _.get(translationObject[selectedLanguage], content.translation);
      return text;
    } else {
      return "";
    }
  };
  useEffect(() => {
    async function getContent() {
      setIsLoading(true);
      const res = await apiGetSectionGroupById(sectionId);
      res?.data ? setSectionGroup(res.data) : setSectionGroup({});
      if (
        res?.data?.sections?.length &&
        res?.data?.sections[0]?.scenes?.length
      ) {
        setActiveScene(res.data.sections[0].scenes);
        setActiveSectionIndex(res.data.sections[0].index);
      }
      setIsLoading(false);
    }
    getContent();
  }, []);

  useEffect(() => {
    const id = sectionGroup?.titleID?.split(".")[1];
    setSectionGroupTitle(
      translationObject?.[selectedLanguage]?.sections["section-group-title"][id]
    );
  }, [sectionGroup]);
  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="main-content container-fluid">
          <div className="common-overview-header">
            <div className="lerners-info">
              <div className="lerners-info-edit-title">
                {edit ? (
                  <input
                    type="text"
                    className="form-control"
                    value={sectionGroupTitle}
                    onChange={(e) => {
                      setSectionGroupTitle(e.target.value);
                    }}
                    placeholder="1.1 Welcome to Ocado"
                  />
                ) : (
                  <h2>
                    {sectionGroup.index + ".0 "} {sectionGroupTitle}
                  </h2>
                )}
                <img
                  onClick={() => setEdit((edit) => !edit)}
                  src={edit ? EditCutIcon : EditBlackIcon}
                />
              </div>

              <span>{sectionGroup.sections?.length} Scenes</span>
            </div>
            <div className="button-wrap">
              <button
                onClick={() => handleUpdate()}
                className="common-btn btn-bg-green-color ml-20"
              >
                Update Content
              </button>
            </div>
          </div>

          <div className="overview-table-header">
            <h4>Edit Scene Content</h4>
            <Link className="overview-back" to="/content/content-menu">
              <img src={AngleLeftBlackIcon} alt="icon" />
              Back
            </Link>
          </div>

          <div className="overview-table-block">
            <div className="overview-tab-holder">
              <ul
                className="nav nav-tabs common-nav-tabs"
                id="overviewTab"
                role="tablist"
              >
                {sectionGroup.sections.map(
                  (subSection: {
                    index: number;
                    subSection: number;
                    scenes: [any];
                  }) => {
                    return (
                      subSection.scenes && (
                        <li className="nav-item" role="presentation">
                          <button
                            onClick={() => handleActiveScene(subSection)}
                            className={`nav-link ${
                              activeSectionIndex === subSection.index
                                ? "active"
                                : ""
                            }`}
                            id={`scene${subSection.index}-tab`}
                            type="button"
                            role="tab"
                            aria-controls="sceneOne"
                            aria-selected="true"
                          >
                            Scene {subSection.subSection}
                          </button>
                        </li>
                      )
                    );
                  }
                )}
              </ul>
              <LanguageDropdown showText={true} altStyle={true} />
            </div>

            <div className="tab-content" id="sceneTabContent">
              <div
                className="tab-pane fade show active"
                role="tabpanel"
                aria-labelledby="sceneOne-tab"
              >
                <div className="row gap-30">
                  {activeScene &&
                    activeScene[currentPage - 1] &&
                    activeScene[currentPage - 1].map(
                      (scene: any, index: number) => (
                        <div className="col-lg-12 col-xl-5">
                          <div className="edit-content-header">
                            <h2>{scene.label}</h2>
                            <button
                              onClick={() => handleSave(index)}
                              className="common-btn btn-bg-green-color"
                            >
                              Save
                            </button>
                          </div>
                          <form className="floating-form common-floating-form mb-40">
                            {scene?.content?.map((content: any) => {
                              {
                                switch (content.type) {
                                  case "input":
                                    return (
                                      <div className="form-floating mb-4">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={content.translation}
                                          onChange={(e) => handleInputChange(e)}
                                          value={
                                            inputForm[content.translation] ??
                                            getInputValues(content)
                                          }
                                          disabled={!content.translation}
                                          placeholder="1.1 Welcome to Ocado"
                                        />
                                        <label htmlFor="floatingInput">
                                          {content.label}
                                        </label>
                                      </div>
                                    );
                                  case "input narration":
                                    return (
                                      <div className="form-floating mb-4">
                                        <VideoLink
                                          title={content.label}
                                          content={content}
                                          handleLinkChange={handleInputChange}
                                          value={
                                            inputForm[content.translation] ??
                                            getInputValues(content)
                                          }
                                          disabled={!content.translation}
                                        />
                                      </div>
                                    );
                                  case "input text":
                                    return (
                                      <div className="form-floating mb-4">
                                        <FloatingDescription
                                          handleChange={handleInputChange}
                                          content={content}
                                          value={
                                            inputForm[content.translation] ??
                                            getInputValues(content)
                                          }
                                          disabled={!content.translation}
                                          title={content.label}
                                        />
                                      </div>
                                    );
                                  case "Browse File":
                                    return (
                                      <BrowseFile
                                        title={content.label}
                                        content={content}
                                        handleChange={handleChangeUpload}
                                        value={
                                          inputForm[content.translation] ??
                                          getInputValues(content)
                                        }
                                        disabled={!content.translation}
                                      />
                                    );
                                  default:
                                    return "";
                                }
                              }
                            })}
                            {scene?.options?.map((option: any) => {
                              return (
                                <>
                                  <div className="edit-content-header">
                                    <h2>{option.label}</h2>
                                  </div>
                                  {option?.content?.map((content: any) => {
                                    {
                                      switch (content.type) {
                                        case "input":
                                          return (
                                            <div className="form-floating mb-4">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={content.translation}
                                                onChange={(e) =>
                                                  handleInputChange(e)
                                                }
                                                value={
                                                  inputForm[
                                                    content.translation
                                                  ] ?? getInputValues(content)
                                                }
                                                placeholder="1.1 Welcome to Ocado"
                                              />
                                              <label htmlFor="floatingInput">
                                                {content.label}
                                              </label>
                                            </div>
                                          );
                                        case "input narration":
                                          return (
                                            <div className="form-floating mb-4">
                                              <VideoLink
                                                title={content.label}
                                                content={content}
                                                handleLinkChange={
                                                  handleInputChange
                                                }
                                                value={
                                                  inputForm[
                                                    content.translation
                                                  ] ?? getInputValues(content)
                                                }
                                              />
                                            </div>
                                          );
                                        case "input text":
                                          return (
                                            <div className="form-floating mb-4">
                                              <FloatingDescription
                                                handleChange={handleInputChange}
                                                content={content}
                                                value={
                                                  inputForm[
                                                    content.translation
                                                  ] ?? getInputValues(content)
                                                }
                                                disabled={!content.translation}
                                                title={content.label}
                                              />
                                            </div>
                                          );
                                        case "Browse File":
                                          return (
                                            <div className="form-floating mb-4">
                                              <BrowseFile
                                                title={content.label}
                                                content={content}
                                                handleChange={
                                                  handleChangeUpload
                                                }
                                                value={
                                                  inputForm[
                                                    content.translation
                                                  ] ?? getInputValues(content)
                                                }
                                                disabled={!content.translation}
                                              />
                                            </div>
                                          );
                                      }
                                    }
                                  })}
                                </>
                              );
                            })}
                          </form>
                          {scene.preview && (
                            <IntroSlidePreview
                              title={getInputValues(scene.content[1])}
                              description={getInputValues(scene.content[2])}
                            />
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
          {activeScene.length > 1 && (
            <Pagination
              currentPage={currentPage}
              totalCount={activeScene.length}
              totalPagesProps={activeScene.length}
              onPageChange={(currentPage: number) =>
                setCurrentPage(currentPage)
              }
              showPerPage={false}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default EditSceneContent;
