import { Fragment } from 'react';
import { Link } from 'react-router-dom';

const ContentOverviewList = ({ sectionGroups } : any) => {
	return (
		<Fragment>
			{
				sectionGroups.map((section: any) => (
					<div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 d-flex mb-30">
				<div className="content-overview-list">
					<div className="img-wrap">
						<img src={section.thumbnail} alt="content overview" />
					</div>
					<div className="info-wrap">
						<h4> { section.index + '.0'  } { section.title} </h4>
					</div>
					<div className="btn-wrap">
						<Link to={`/content/content-menu/edit-scene-content/${section.id}`} 
							className="common-btn btn-bg-primary-color">
							Edit Content
						</Link>
					</div>
				</div>
			</div>
				)

				)
			}
		</Fragment>
	);
};

export default ContentOverviewList;
