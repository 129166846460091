import {Fragment} from "react";

import PasswordLogs from "./passwordLogs";
import UpdateCentralPassword from "./updateCentralPassword";
import AutomaticPassword from "./automaticPassword";

//images
import AngleLeftBlackIcon from "assets/images/common/angle-left-black-icon.svg";

const Passwords = () => {
  return (
    <Fragment>
      <div className="main-content container-fluid">
        <div className="common-overview-header ">
          <div className="lerners-info">
            <h2>Password Management</h2>
          </div>
        </div>

        <div className="overview-table-header">
          <h4>Update Platform Password</h4>
          <span className="overview-back">
            <img src={AngleLeftBlackIcon} alt="icon" />
            Back
          </span>
        </div>

        <div className="row">
          <div className="col-lg-12 col-xl-6">
            <UpdateCentralPassword />
            <AutomaticPassword />
          </div>
          <div className="col-lg-12 col-xl-6">
            <PasswordLogs />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Passwords;
