import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import { ApiRequestUrl } from './constants';

export const client = (props: AxiosRequestConfig): AxiosPromise => axios({
  method: props.method,
  baseURL: `${ApiRequestUrl.BASE}/`,
  url: props.url,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
  data: props.data,
});



export const clientFile = (props: AxiosRequestConfig): AxiosPromise => axios({
  method: props.method,
  baseURL: `${ApiRequestUrl.BASE}/`,
  url: props.url,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
  data: props.data,
});
