import { Fragment, useState, useEffect, Suspense } from "react";
import { apiDeleteUsers, apiGetUserList } from "api/user";
import Moment from "react-moment";
import { useHistory } from "react-router";
import Loading from "components/Loader";
import ProgressBar from "components/ProgressBar";
import { success } from "utils/toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

//images
import SearchBlackIcon from "assets/images/common/search-black-icon.svg";
import FiltersIcon from "assets/images/common/filters-icon.svg";
import ArrowPathIcon from "assets/images/common/arrow-path-icon.svg";
import LongArrowUpIcon from "assets/images/common/long-arrow-up-icon.svg";
import ViewUsersIcon from "assets/images/common/view-users-icon.svg";
import EditIcon from "assets/images/common/edit-icon.svg";
import LearnerImg from "assets/images/common/default.png";

const ListLearner = ({
  isAddLearner,
  setIsAddLearner,
  isBulkImport,
  setIsBulkImport,
  isEditLearner,
  setIsEditLearner,
}: {
  isAddLearner: boolean;
  setIsAddLearner: (isAddLearner: boolean) => void;
  isBulkImport: boolean;
  setIsBulkImport: (isBulkImport: boolean) => void;
  isEditLearner: any;
  setIsEditLearner: (isEditLearner: any) => void;
}) => {
  const history = useHistory();
  const [selectedLearners, setSelectedLearners] = useState<string[]>([]);
  const [isEditable, setEditable] = useState(false);
  const [learners, setLearners] = useState([]);

  const [totalLearners, setTotalLearners] = useState(-1);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [recent, setRecent] = useState<boolean>(false);
  //pagination & search
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(1);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [deleteCount, setDeleteCount] = useState<number>(0); //added in order to refresh list users while deleting
  //search

  const checkBoxChanged = (event: any, id: string) => {
    if (id === 'all') {
      if (event.target.checked) {
        setSelectedLearners([...learners.map((learner: any) => (learner._id))])
        setEditable(true);
      } else {
        setSelectedLearners([]);
        setEditable(false);
      }

    }
    else if (event.target.checked) {
      setSelectedLearners([...selectedLearners, id]);
      setEditable(event.target.checked);
    } else {
      let nSelectedLearners = [...selectedLearners].remove(id);
      setSelectedLearners(nSelectedLearners);

      if (!nSelectedLearners.length) {
        setEditable(false);
      }
    }
  };

  const EditUser = () => {
    setIsEditLearner(selectedLearners);
  };

  const ViewUser = () => {
    history.push(`/learners/viewLearner/${selectedLearners}`);
  };

  const DeleteUser = () => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete selected user/s.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            apiDeleteUsers({ userIds: selectedLearners }).then((data: any) => {
              if (data.status === "success") {
                success(data.message);
                setSelectedLearners([]);
                setDeleteCount(deleteCount + 1);
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
    // setIsEditLearner(selectedLearners);
  };

  useEffect(() => {
    if (isAddLearner || isEditLearner.length === 1) {
      return;
    }
    let data = {
      ...(search.trim() !== ""
        ? { search, skip, limit, recent }
        : { skip, limit, recent }),
    };
    setisLoading(true);
    apiGetUserList(data).then((data) => {
      setisLoading(false);
      if (data.data.users) {
        setLearners(data.data.users);
        setTotalLearners(data.data.meta.itemCount);
        setPageCount(data.data.meta.pageCount);
        setHasNextPage(data.data.meta.hasNextPage);
        setHasPrevPage(data.data.meta.hasPrevPage);
        setSelectedLearners([]);
        setEditable(false);
      }
    });
  }, [isAddLearner, skip, limit, search, recent, isEditLearner, deleteCount]);

  return (
    <Fragment>
      <div className="main-content container-fluid">
        <div className="common-overview-header">
          <div className="lerners-info">
            <h2>All Learners</h2>
            <span>{totalLearners} Learners</span>
          </div>
          <div className="input-group search-wrap">
            <span className="input-group-text" id="basic-addon1">
              <img src={SearchBlackIcon} alt="search learners" />
            </span>
            <input
              type="search"
              className="form-control"
              placeholder="Search Learners"
              aria-label="searchLearners"
              aria-describedby="basic-addon1"
              onChange={(event: any) => {
                setSearch(event.target.value);
                setSkip(1);
                setRecent(false);
              }}
            />
          </div>
          <div className="button-wrap">
            <a
              className="common-btn btn-border-color"
              href={void (0)}
              onClick={() => {
                setIsAddLearner(true);
                setIsBulkImport(true);
              }}
            >
              Bulk Import
            </a>
            <a
              className="common-btn btn-bg-green-color ml-20"
              href={void (0)}
              onClick={() => {
                setIsAddLearner(true);
                setIsBulkImport(false);
              }}
            >
              Add Learner
            </a>
          </div>
        </div>

        <div className="overview-table-header">
          <h4>Overview</h4>
        </div>

        <div className="overview-table-block">
          <div className="overview-tab-holder">
            <ul
              className="nav nav-tabs common-nav-tabs"
              id="overviewTab"
              role="tablist"
            >
              <li
                className={recent ? "nav-item" : "nav-item active"}
                role="presentation"
              >
                <button
                  className={recent ? "nav-link" : "nav-link active"}
                  id="learners-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#learners"
                  type="button"
                  role="tab"
                  aria-controls="learners"
                  aria-selected={recent ? false : true}
                  onClick={() => {
                    setRecent(false);
                    setSkip(1);
                  }}
                >
                  Learners
                </button>
              </li>
              <li
                className={recent ? "nav-item active" : "nav-item"}
                role="presentation"
              >
                <button
                  className={recent ? "nav-link active" : "nav-link"}
                  id="recently-added-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#recently-added"
                  type="button"
                  role="tab"
                  aria-controls="recently-added"
                  aria-selected={recent ? true : false}
                  onClick={() => {
                    setRecent(true);
                    setSkip(1);
                  }}
                >
                  Recently Added
                </button>
              </li>
            </ul>
            <a className="show-filters" href={void (0)}>
              <img src={FiltersIcon} alt="icon" />
              Show Filters
            </a>
          </div>
          <div className="tab-content" id="overviewTabContent">
            <div
              className="tab-pane fade show active"
              id="learners"
              role="tabpanel"
              aria-labelledby="learners-tab"
            >
              <div className="table-responsive overview-table-list">
                {isLoading ? (
                  <Loading />
                ) : totalLearners === 0 ? (
                  <span className="no-learners-found">"No Learners Found"</span>
                ) : (
                  <table className="table">
                    <thead
                      className={
                        isEditable
                          ? "overview-thead-hidden"
                          : "overview-thead-showing"
                      }
                    >
                      <tr>
                        <th scope="col">
                          <div className="thead-text">
                            <div className="common-tickbox">
                              <input
                                type="checkbox"
                                id="check"
                                onChange={(event: any) =>
                                  checkBoxChanged(event, "all")
                                }
                              />
                              <label htmlFor="check" className="thik-border">
                                <img src={ArrowPathIcon} alt="icon" />
                              </label>
                            </div>
                            <div className="thead-img-wrap">
                              Learner Name
                              <img src={LongArrowUpIcon} alt="icon" />
                            </div>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead-text">Email ID</div>
                        </th>
                        <th scope="col">
                          <div className="thead-text">Date Created</div>
                        </th>
                        <th scope="col">
                          <div className="thead-text">
                            Course Progress
                            <img src={LongArrowUpIcon} alt="icon" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <thead
                      className={
                        isEditable
                          ? "overview-thead-showing"
                          : "overview-thead-hidden"
                      }
                    >
                      <tr>
                        <td colSpan={Number("4")}>
                          <div className="thead-view-learner">
                            <div className="thead-text">
                              <div className="common-tickbox">
                                <input type="checkbox" id="check" defaultChecked />
                                <label htmlFor="check" className="thik-border">
                                  <img src={ArrowPathIcon} alt="icon" />
                                </label>
                              </div>
                            </div>
                            <ul className="view-learner-list">
                              {selectedLearners.length === 1 ? (
                                <Fragment>
                                  <li>
                                    <span onClick={ViewUser}>
                                      <img src={ViewUsersIcon} alt="icon" />
                                      View Learner
                                    </span>
                                  </li>
                                  <li>
                                    <span onClick={EditUser}>
                                      <img src={EditIcon} alt="icon" />
                                      Edit
                                    </span>
                                  </li>
                                </Fragment>
                              ) : (
                                ""
                              )}
                              <li>
                                <span onClick={DeleteUser}>
                                  <img src={ViewUsersIcon} alt="icon" />
                                  Delete
                                </span>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <Suspense fallback={<h1>Loading profile...</h1>}>
                        {learners.map(
                          (learner: {
                            first_name: string;
                            last_name: string;
                            email: string;
                            createdAt: string;
                            _id: string;
                            profile_picture: string;
                            courseProgress?: any;
                          }) => {
                            let totalProgressWidth: any = learner.courseProgress
                              .length
                              ? {
                                width: `${learner.courseProgress[0].total_progress.toFixed(
                                  2
                                )}%`,
                              }
                              : "";
                            return (
                              <tr key={learner._id}>
                                <td>
                                  <div className="info-wrap">
                                    <div className="common-tickbox">
                                      <input
                                        key={learner._id}
                                        type="checkbox"
                                        id={learner._id}
                                        checked={selectedLearners.includes(learner._id)}
                                        onChange={(event: any) => {
                                          checkBoxChanged(event, learner._id);
                                        }}
                                      />
                                      <label htmlFor={learner._id}></label>
                                    </div>
                                    <div className="img-wrap">
                                      <img
                                        src={
                                          learner.profile_picture === "" ||
                                            typeof learner.profile_picture ==
                                            "undefined"
                                            ? LearnerImg
                                            : learner.profile_picture
                                        }
                                        alt="user"
                                      />
                                    </div>
                                    <span>
                                      {learner.first_name} {learner.last_name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="info-wrap">
                                    {learner.email}
                                  </div>
                                </td>
                                <td>
                                  <div className="info-wrap">
                                    <Moment format="DD/MM/YY HH:mm">
                                      {learner.createdAt}
                                    </Moment>
                                  </div>
                                </td>
                                <td>
                                  {learner.courseProgress?.length ? (
                                    <ProgressBar
                                      percent={
                                        learner.courseProgress[0].total_progress
                                      }
                                    />
                                  ) : (
                                    <ProgressBar percent={0} />
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </Suspense>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="overview-table-footer fixed-bottom">
          <div className="row-page-wrap">
            <h4>Rows per page</h4>
            <select
              className="form-select"
              aria-label="Default select example"
              value={limit}
              onChange={(event: any) => {
                setLimit(event.target.value);
                setSkip(1);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="custom-pagination">
            <div className="pagination-wrap">
              <a
                href={void (0)}
                onClick={(event: any) => (hasPrevPage ? setSkip(skip - 1) : "")}
                className={hasPrevPage ? "" : "disabled"}
              >
                <svg
                  width={8}
                  height={16}
                  viewBox="0 0 8 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.997 1.59l-5 6.532 5 6.532"
                    stroke="#6F75BC"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
              <input
                type="text"
                className="form-control"
                value={skip}
                disabled
              />
              <a
                href={void (0)}
                onClick={(event: any) => (hasNextPage ? setSkip(skip + 1) : "")}
                className={hasNextPage ? "" : "disabled"}
              >
                <svg
                  width={8}
                  height={15}
                  viewBox="0 0 8 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 13.926l4.755-6.211L1.5 1.5"
                    stroke="#6F75BC"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
            <span className="showing-page">of {pageCount}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ListLearner;
