import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

//Image Import
import EditBlackIcon from 'assets/images/common/edit-black-icon.svg';
import EditCutIcon from 'assets/images/common/pencil-cut.svg';

const VideoLink = (
	{title, value, content, handleLinkChange, disabled= false} : 
	{title: string, value: string, content: any, handleLinkChange: Function, disabled?: boolean}) => {
	const [edit, setEdit] = useState<boolean>(false);
	return (
		<Fragment>
			{edit ? (
				<input
					type="url"
					className="form-control pr-60"
					name={content.translation}
					onChange={(e) => handleLinkChange(e)}
					value={value}
					disabled={disabled}
					placeholder={title}
				/>
			) : (
				<a
					className="form-control url-text"
					target="_blank"
					href={value} rel="noreferrer"
				>
					{value}
				</a>
			)}
			<div className="login-show-password">
				<img
					onClick={() => setEdit((edit) => !edit)}
					src={edit ? EditBlackIcon : EditCutIcon}
				/>
			</div>
			<label htmlFor="floatingInput">{title}</label>
		</Fragment>
	);
};

export default VideoLink;
