/* eslint-disable max-len */

import { SvgIcon, SvgIconProps } from "@material-ui/core";

export function SmallArrow(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} width="9" height="15" viewBox="0 0 9 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.582 7.845.57 12.855a1.091 1.091 0 1 0 1.542 1.543l6.545-6.546-1.68-1.68-4.887-4.887A1.09 1.09 0 1 0 .549 2.827l5.033 5.018z"
        fill={props.fill}
      />
    </SvgIcon>
  );
}
