import { Fragment, useEffect, useState } from "react";
import Loading from "components/Loader";

import LoginAreaChart from "components/reports/loginAreaChart";
import { apiGetDailyLogins } from "api/analytics";

const DailyLogins = () => {
  const [today, setToday] = useState(0);
  const [dates, setDates] = useState([]);
  const [counts, setCounts] = useState([]);
  const [isLoading, setisLoading] = useState<boolean>(false);

  useEffect(() => {
    setisLoading(true);
    apiGetDailyLogins().then((responseData: any) => {
      setisLoading(false);
      if (responseData.status === "success") {
        setDates(responseData.data.map((a: any) => a.date));
        setCounts(responseData.data.map((a: any) => a.count));
        const today = new Date();
        const totalActivetoday = responseData.data.find((date: { date: any; })=>{
          let loginDate = new Date(date.date)
          if(today.getDate()===loginDate.getDate() && today.getMonth() === loginDate.getMonth()){
            return true
          }else{
            return false
          }
        })
        setToday(totalActivetoday.count);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="common-box">
        <div className="reports-overview-wrap">
          <div className="reports-overview-detail">
            <h4>Daily Logins</h4>
            <span>Globally</span>
          </div>
          <div className="reports-overview-icon">
            <span>
              Today: <span>{today} Logins</span>
            </span>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>

            <div className="area-chart-wrap">
              <div className="area-line-chart">
                <LoginAreaChart dates={dates} counts={counts} />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default DailyLogins;
