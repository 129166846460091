import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const Loading = () => {
	return (
		<div className="loader-wrap">
			<Loader type="ThreeDots" color="#00BFFF" height={60} width={60} />
		</div>
	);
};

export default Loading;
