import { Fragment } from 'react';
import AnnouncementIcon from 'assets/images/common/announcement-icon.svg';

const IntroSlidePreview = ({title, description}: {title: string, description: string}) => {
	return (
		<Fragment>
			<div className="edit-content-header">
				<h2>Slide Preview</h2>
			</div>
			<div className="common-slide-preview intro-slide-preview">
				<img
					className="announcement-icon"
					src={AnnouncementIcon}
					alt="announcement icon"
				/>
				<h2>{ title }</h2>
				<p>
					{ description }
				</p>
			</div>
		</Fragment>
	);
};

export default IntroSlidePreview;
