import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import {Line} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AreaLineChart = ({
  dates,
  counts,
}: {
  dates: Array<string>;
  counts: Array<any>;
}) => {
  const options = {
    aspectRatio: 1.7,
    maintainAspectRatio: true,
    scales: {
      yAxes: {
        display: false,
      },
      xAxes: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: "#263238",
        //titleAlign: 'center',
        //bodyAlign: 'center',
      },
    },
  };

  const data = {
    labels: dates,
    datasets: [
      {
        data: counts,
        fill: true,
        borderWidth: 1.5,
        backgroundColor: "#DDD1FB",
        borderColor: "#7241F0",
        tension: 0.5,
      },
    ],
  };

  return <Line options={options} data={data} />;
};

export default AreaLineChart;
