import {Fragment, useRef} from "react";
import SingleLearner from "./singleLearner";
import BulkImportLearner from "./bulkImportLearner";
//images
import AngleLeftBlackIcon from "assets/images/common/angle-left-black-icon.svg";

const AddLearner = ({
  isAddLearner,
  setIsAddLearner,
  isBulkImport,
  setIsBulkImport,
  isEditLearner,
  setIsEditLearner,
}: {
  isAddLearner: boolean;
  setIsAddLearner: (isAddLearner: boolean) => void;
  isBulkImport: boolean;
  setIsBulkImport: (isBulkImport: boolean) => void;
  isEditLearner: any;
  setIsEditLearner: (isEditLearner: any) => void;
}) => {
  // Modal Reference and Close Function if clicked outside of Add Learner Component
  const modalRef: any = useRef();

  const closeModal = (e: any) => {
    if (modalRef.current === e.target) {
      setIsAddLearner(false);
      setIsBulkImport(false);
      setIsEditLearner([]);
    }
  };

  return (
    <Fragment>
      <div
        style={{width: "100%", height: "100%", position: "fixed"}}
        ref={modalRef}
        onClick={(e) => closeModal(e)}
      >
        <div className="modal-content-sidebar active">
          <div className="overview-table-header">
            <h4>
              {" "}
              {isEditLearner.length !== 1 ? "Add New Learners" : "Edit Learner"}
            </h4>
            <span
              className="overview-back"
              onClick={() => {
                setIsAddLearner(false);
                setIsBulkImport(false);
                setIsEditLearner([]);
              }}
            >
              <img src={AngleLeftBlackIcon} alt="icon" />
              Back
            </span>
          </div>
          <div className="overview-tab-holder">
            <ul
              className="nav nav-tabs common-nav-tabs"
              id="overviewTab"
              role="tablist"
            >
              {isEditLearner.length !== 1 ? (
                <Fragment>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${isBulkImport ? "" : "active"}`}
                      id="add-learners-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#add-learners"
                      type="button"
                      role="tab"
                      aria-controls="add-learners"
                      aria-selected={isBulkImport ? false : true}
                      onClick={() => setIsBulkImport(false)}
                    >
                      {isEditLearner.length !== 1
                        ? "Add a Learner"
                        : "Edit a Learner"}
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${isBulkImport ? "active" : ""}`}
                      id="import-learner-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#import-learner"
                      type="button"
                      role="tab"
                      aria-controls="import-learner"
                      aria-selected={isBulkImport ? true : false}
                      onClick={() => setIsBulkImport(true)}
                    >
                      Import Learners
                    </button>
                  </li>
                </Fragment>
              ) : (
                ""
              )}
            </ul>
          </div>
          <div className="tab-content" id="overviewTabContent">
            {isEditLearner.length === 1 || (!isBulkImport && isAddLearner) ? (
              <SingleLearner
                isAddLearner={isAddLearner}
                setIsAddLearner={setIsAddLearner}
                isBulkImport={isBulkImport}
                setIsBulkImport={setIsBulkImport}
                isEditLearner={isEditLearner}
                setIsEditLearner={setIsEditLearner}
              />
            ) : (
              <BulkImportLearner
                isAddLearner={isAddLearner}
                setIsAddLearner={setIsAddLearner}
                isBulkImport={isBulkImport}
                setIsBulkImport={setIsBulkImport}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddLearner;
