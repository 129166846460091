import { useState, useEffect } from 'react';
const TimeProgressbar = ({
	progressDone,
	progressTitle,
	bgColor,
}: {
	progressDone: string;
	progressTitle: string;
	bgColor: string;
}) => {
	const [style, setStyle] = useState({});
	useEffect(() => {
		const newStyle = {
			opacity: 1,
			width: `${progressDone}%`,
			title: `${progressTitle}`,
			backgroundColor: bgColor,
			color:
				bgColor === 'rgba(133, 92, 248, 0.3)' || bgColor === 'rgba(133, 92, 248, 0)'
					? '#1B1C21'
					: bgColor === 'rgba(133, 92, 248, 0.2)' 
					? '#747C80'
					: '#fff',
		};
		setStyle(newStyle);
	}, [bgColor, progressTitle, progressDone]);

	return (
		<div className="react-progress react-progress-time">
			<div className="progress-done" style={style}>
				<div className="progress-text">
					<div title={progressTitle} className="progress-text">
						{progressTitle}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimeProgressbar;
