import { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { apiGetContent } from 'api/section';
import Pagination from 'components/pagination'
//images
import AngleLeftBlackIcon from 'assets/images/common/angle-left-black-icon.svg';
import FiltersIcon from 'assets/images/common/filters-icon.svg';
import ArrowPathIcon from 'assets/images/common/arrow-path-icon.svg';
import EditIcon from 'assets/images/common/edit-icon.svg';
import DeleteIcon from 'assets/images/common/delete-icon.svg';
import EditOrderIcon from 'assets/images/common/edit-order-icon.svg';

const ContentMenu = () => {
	const history = useHistory()
	const [sectionGroups, setSectionGroups] = useState([]);
	const [selectedSectionGroup, setSelectedSectionGroup] = useState<string[]>([]);
	const [isEditable, setEditable] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(8);
	const [totalCount, setTotalCount] = useState(10);
	const checkBoxChanged = (event: any, id: string) => {
		if(id === 'all') {
			if(event.target.checked) {
				setSelectedSectionGroup([...sectionGroups.map((sectionGroup: any)=> (sectionGroup._id))])
				setEditable(true);
			} else {
				setSelectedSectionGroup([]);
				setEditable(false);
			}
			
		}
		else if (event.target.checked) {
			setSelectedSectionGroup([...selectedSectionGroup, id]);
			setEditable(event.target.checked);
		} else {
			let nSelectedSectionGroup = [...selectedSectionGroup].remove(id);
			setSelectedSectionGroup(nSelectedSectionGroup);
			if (!nSelectedSectionGroup.length) {
				setEditable(false);
			}
		}
	};

	const EditUser = () => {
		history.push(`/content/content-menu/edit-scene-content/${selectedSectionGroup[0]}`);
	}

	useEffect(() => {
		(async function getContent() {
			const res = await apiGetContent({ skip: currentPage, limit: perPage });
			if (res) {
				setSectionGroups(res.data?.sectionGroups);
				setTotalCount(res.data?.meta?.itemCount)
			}
		})();
	}, [currentPage, perPage]);
	return (
		<Fragment>
			<div className="main-content container-fluid">
				<div className="common-overview-header">
					<div className="lerners-info">
						<h2>Content Menu</h2>
						<span>{totalCount} Sections</span>
					</div>
				</div>

				<div className="overview-table-header">
					<h4>Menu Items</h4>
					<Link className="overview-back" to="/content/overview">
						<img src={AngleLeftBlackIcon} alt="icon" />
						Back
					</Link>
				</div>

				<div className="overview-table-block">
					<div className="overview-tab-holder">
						<ul
							className="nav nav-tabs common-nav-tabs"
							id="sideMenu"
							role="tablist"
						>
							<li className="nav-item" role="presentation">
								<button
									className="nav-link active"
									id="side-menu-tab"
									data-bs-toggle="tab"
									data-bs-target="#side-menu"
									type="button"
									role="tab"
									aria-controls="side-menu"
									aria-selected="true"
								>
									Side Menu
								</button>
							</li>
						</ul>
						<a className="show-filters" href="javascript:void(0);">
							<img src={FiltersIcon} alt="icon" />
							Show Filters
						</a>
					</div>

					<div className="tab-content" id="sideMenuContent">
						<div
							className="tab-pane fade show active"
							id="side-menu"
							role="tabpanel"
							aria-labelledby="side-menu-tab"
						>
							<div className="table-responsive overview-table-list login-history-table">
								<table className="table">
									<thead className={isEditable ? "overview-thead-hidden" : "overview-thead-showing"}>
										<tr>
											<th>
												<div className="thead-text">
													<div className="common-tickbox">
														<input type="checkbox" id="check" onChange={(event: any) =>
															checkBoxChanged(event, "all")
														} />
														<label htmlFor="check" className="thik-border">
															<img src={ArrowPathIcon} alt="icon" />
														</label>
													</div>
													<div className="thead-text">Section Title</div>
												</div>
											</th>
											<th>
												<div className="thead-text">Sub Sections</div>
											</th>
										</tr>
									</thead>
									<thead className={isEditable ? "overview-thead-showing" : "overview-thead-hidden"}>
										<tr>
											<th colSpan={Number("2")}>
												<div className="thead-view-learner">
													<div className="thead-text">
														<div className="common-tickbox">
															<input type="checkbox" id="check" checked />
															<label htmlFor="check" className="thik-border">
																<img src={ArrowPathIcon} alt="icon" />
															</label>
														</div>
													</div>
													<ul className="view-learner-list">
														{ selectedSectionGroup.length === 1 && (
															<>
																<li>
																	<span>
																		<img src={EditOrderIcon} alt="icon" />
																		Edit Order
																	</span>
																</li>
																<li>
																	<span onClick={EditUser}>
																		<img src={EditIcon} alt="icon" />
																		Edit
																	</span>
																</li>	
															</>
														)}
														<li>
															<span>
																<img src={DeleteIcon} alt="icon" />
																Delete
															</span>
														</li>
													</ul>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										{sectionGroups.map((section: any) => (<tr key={section._id}>
											<td>
												<div className="info-wrap">
													<div className="common-tickbox">
														<input type="checkbox"
															key={section._id}
															id={section._id}
															checked={selectedSectionGroup.includes(section._id)}
															onChange={(event: any) => {
																checkBoxChanged(event, section._id);
															}} />
														<label htmlFor={section._id}></label>
													</div>
													<span>{section.index + '.0'} {section.title}</span>
												</div>
											</td>
											<td>
												<div className="info-wrap">{section.sections.length} Scenes</div>
											</td>
										</tr>))}

									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<Pagination currentPage={currentPage}
					totalCount={totalCount}
					perPage={perPage}
					onPageChange={(currentPage: number) => setCurrentPage(currentPage)}
					onPerPageChange={(perPage: number) => setPerPage(perPage)} />
			</div>
		</Fragment>
	);
};

export default ContentMenu;
