/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx} from "@emotion/react";
import "sass/app.scss";
import {Route, Switch, BrowserRouter as Router} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import {Wrapper} from "components";

import {
  Login,
  ForgetPassword,
  ResetPassword,
  UpdatePassword,
  SuccessPassword,
  VerifyEmail,
  PageNotFound
} from "screens";

function FullPageErrorFallback({error}: any) {
  return (
    <div
      role="alert"
      css={{
        color: "red",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={FullPageErrorFallback}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={Login} />
            <Route exact path="/dashboard">
              <Wrapper page="dashboard" />
            </Route>
            <Route exact path="/learners/overview">
              <Wrapper page="learners/overview" />
            </Route>
            <Route exact path="/learners/passwords">
              <Wrapper page="learners/passwords" />
            </Route>
            <Route exact path="/learners/viewLearner/:id">
              <Wrapper page="learners/viewLearner" />
            </Route>
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route
              exact
              path="/update-password/:userId/:token"
              component={UpdatePassword}
            />
            <Route exact path="/success-password" component={SuccessPassword} />
            <Route exact path="/reports/section-progress">
              <Wrapper page="reports/section-progress" />
            </Route>
            <Route exact path="/reports/overflow">
              <Wrapper page="reports/overflow" />
            </Route>
            <Route exact path="/content/overview">
              <Wrapper page="content/overview" />
            </Route>
            <Route exact path="/content/content-menu">
              <Wrapper page="content/content-menu" />
            </Route>
            <Route exact path="/content/content-menu/edit-scene-content/:sectionId">
              <Wrapper page="content/content-menu/edit-scene-content" />
            </Route>
            <Route exact path="/verify-email/:token" component={VerifyEmail} />
            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
