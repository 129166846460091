import { useAuth } from "context/authContext";
import {Link} from "react-router-dom";

//Image Import
import OcadoLogo from "assets/images/common/ocado-logo.svg";
// import LiveSiteIcon from "assets/images/common/external-link-icon.svg";
// import HelpIcon from "assets/images/common/help-circle-icon.svg";
// import SearchIcon from "assets/images/common/search-icon.svg";
// import MessageIcon from "assets/images/common/message-icon.svg";
// import BellIcon from "assets/images/common/bell-icon.svg";
import LogOutIcon from "assets/images/common/logout-icon.svg";
// const baseUrl = process.env.REACT_APP_BASEURL;

const NavBar = () => {
  const { state, dispatch } = useAuth();
  const { loggedInUser } = state;
  const LogOut = () => {
    dispatch({type: 'removeUser'})
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top main-navigation">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            src={OcadoLogo}
            alt="myXR CMS"
            className="d-inline-block align-text-top"
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav navbar-right-nav">
            {/* <li className="nav-item nav-item-view">
              <Link to="/" className="nav-link" aria-current="page">
                View live site
                <img src={LiveSiteIcon} alt="view live site" />
              </Link>
            </li>
            <li className="nav-item nav-item-help">
              <Link to="/" className="nav-link">
                <img src={HelpIcon} alt="help" />
                Help
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <img src={SearchIcon} alt="search" />
                <span className="sm-show">Search</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <img src={MessageIcon} alt="message" />
                <span className="sm-show">Message</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                <img src={BellIcon} alt="bell" />
                <span className="sm-show">Notification</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/" className="nav-link" onClick={LogOut}>
                <img src={LogOutIcon} alt="logout" />
                <span className="sm-show">Logout</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                { loggedInUser?.profile_picture ? <img src={loggedInUser.profile_picture} alt={loggedInUser.first_name} /> :
                  <span className="user-logo">{loggedInUser?.first_name.charAt(0) + loggedInUser?.last_name.charAt(0)}</span>
                }                
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
