import {Fragment, useEffect, useState} from "react";
import Loading from "components/Loader";
import Moment from "react-moment";
import moment from "moment";
import ProgressBar from "components/ProgressBar";
import {useParams} from "react-router-dom";
import { useTranslation } from "context/translationContext";
import {apiGetAllSections} from "api/section";
//images
import LongArrowUpIcon from "assets/images/common/long-arrow-up-icon.svg";

const LearnerProgress = () => {
  const [sectionList, setSectionList] = useState([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { state} = useTranslation();
  const { translationObject } = state;
  //pagination
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  type viewParams = {
    id: string;
  };
  const {id} = useParams<viewParams>();

  useEffect(() => {
    let data = {
      skip: skip,
      limit: limit,
      learner: id,
      course: "61e65fce8441592b9022a9a0",
    };

    setisLoading(true);
    apiGetAllSections(data).then((responseData: any) => {
      setisLoading(false);

      if (
        responseData.status === "success" &&
        responseData.data.sectionProgress
      ) {
        setSectionList(responseData.data.sectionProgress);
        setPageCount(responseData.data.meta.pageCount);
        setHasNextPage(responseData.data.meta.hasNextPage);
        setHasPrevPage(responseData.data.meta.hasPrevPage);
      }
    });
  }, [skip, limit]);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : sectionList.length === 0 ? (
        <span className="no-learners-found">"No Section Progress Found"</span>
      ) : (
        <Fragment>
          <div className="tab-content" id="overviewTabContent">
            <div
              className="tab-pane fade show active"
              id="learner-progress"
              role="tabpanel"
              aria-labelledby="learner-progress-tab"
            >
              <div className="table-responsive overview-table-list">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="thead-text">Section Title</div>
                      </th>
                      <th scope="col">
                        <div className="thead-text">Time Spent</div>
                      </th>
                      <th scope="col">
                        <div className="thead-text">Last Accessed</div>
                      </th>
                      <th scope="col">
                        <div className="thead-text">
                          Section Progress
                          <img src={LongArrowUpIcon} alt="icon" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sectionList.map((section: any) => {
                      let a = new Date(section.total_time_spent * 1000)
                        .toISOString()
                        .substr(11, 8);
                      let t = a.split(":");

                      let timeSpent =
                        parseInt(t[0]) > 0
                          ? `${parseInt(t[0])} hr , ${parseInt(
                              t[1]
                            )} mins & ${parseInt(t[2])} secs`
                          : parseInt(t[1]) > 0
                          ? ` ${parseInt(t[1])} mins & ${parseInt(t[2])} secs`
                          : `${parseInt(t[2])} secs`;

                      return (
                        <tr>
                          <td scope="row">
                            <div className="info-wrap view-learner-text">
                              <span>
                                {section.section?.section}.
                                {section.section?.subSection}{" "}
                                {translationObject?.enGB.sections['section-title'][section.section?.titleID]}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="info-wrap">{timeSpent}</div>
                          </td>
                          <td>
                            <div className="info-wrap">
                              {section.progress ? (
                                <Fragment>
                                  <span>
                                    <Moment format="DD/MM/YY">
                                      {section.updatedAt}
                                    </Moment>
                                  </span>
                                  <span>
                                    <Moment format="HH:mm">
                                      {section.updatedAt}
                                    </Moment>
                                  </span>
                                </Fragment>
                              ) : (
                                "--"
                              )}
                            </div>
                          </td>
                          <td>
                            <ProgressBar percent={section.progress * 100} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overview-table-footer inner-table-footer">
            <div className="row-page-wrap">
              <h4>Rows per page</h4>
              <select
                className="form-select"
                aria-label="Default select example"
                value={limit}
                onChange={(event: any) => {
                  setLimit(event.target.value);
                  setSkip(1);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="custom-pagination">
              <div className="pagination-wrap">
                <a
                  href="javascript:void(0);"
                  onClick={(event: any) =>
                    hasPrevPage ? setSkip(skip - 1) : ""
                  }
                  className={hasPrevPage ? "" : "disabled"}
                >
                  <svg
                    width="8"
                    height="16"
                    viewBox="0 0 8 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.99707 1.59033L1.99707 8.12158L6.99707 14.6538"
                      stroke="#6F75BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <input
                  type="text"
                  className="form-control"
                  value={skip}
                  disabled
                />
                <a
                  href="javascript:void(0);"
                  onClick={(event: any) =>
                    hasNextPage ? setSkip(skip + 1) : ""
                  }
                  className={hasNextPage ? "" : "disabled"}
                >
                  <svg
                    width="8"
                    height="15"
                    viewBox="0 0 8 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 13.9258L6.25488 7.71484L1.5 1.50098"
                      stroke="#6F75BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <span className="showing-page">of {pageCount}</span>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LearnerProgress;
