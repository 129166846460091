const Pagination = ({currentPage, totalCount, perPage = 8, onPageChange = ()=> {}, onPerPageChange, showPerPage = true, totalPagesProps= null}: any) => {
    const totalPages = totalPagesProps || Math.ceil(totalCount/perPage);
    const onNext = () => {
        onPageChange(currentPage + 1);
    }
    const onPrevious = () => {
        onPageChange(currentPage - 1);
    }
    return (
        <div className="overview-table-footer fixed-bottom">
			<div className="row-page-wrap">
				{showPerPage && 
					<>
						<h4>Sections per page</h4>
						<select value={perPage} 
							onChange={(e) =>onPerPageChange(e.target.value)}
							className="form-select" aria-label="Default select example">
							<option value="8">8</option>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="100">100</option>
						</select>
					</>
				}
				
			</div> 
					
					<div className="custom-pagination">
						<div className="pagination-wrap">
							<a href="javascript:void();" onClick={onPrevious} 
                                className={currentPage > 1 ? '' : 'disabled'}>
								<svg
									width="8"
									height="16"
									viewBox="0 0 8 16"
									fill="none" 
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.99707 1.59033L1.99707 8.12158L6.99707 14.6538"
										stroke="#6F75BC"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</a>
							<input type="text" className="form-control" value={currentPage} />
							<a href="javascript:void();" onClick={onNext} 
                                className={currentPage< totalPages ? '' : 'disabled'}>
								<svg
									width="8"
									height="15"
									viewBox="0 0 8 15"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.5 13.9258L6.25488 7.71484L1.5 1.50098"
										stroke="#6F75BC"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</a>
						</div>
						<span className="showing-page">of {totalPages}</span>
					</div>
				</div>
    );
}
export default Pagination;
