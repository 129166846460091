import type { ReactNode } from 'react';
import { createContext, useReducer, useContext } from "react";

const defaultState = { loggedInUser: null };

export type ActionKind = 'setUser' | 'removeUser';
interface User {
  [key: string]: any | null
}
interface Action {
  type: ActionKind;
  payload?: User
}
export type Dispatch = (action: Action) => void
interface State {
  loggedInUser: User | null
};
const AuthContext = createContext<{
  state: State, dispatch: Dispatch
} | undefined>(undefined);

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setUser':
      localStorage.setItem('loggedInUser', JSON.stringify(action.payload));
      return {
        loggedInUser: action.payload || null
      };
    case 'removeUser':
      localStorage.clear();
      // localStorage.removeItem('loggedInUser')
      return { loggedInUser: null }
  }
}


function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, defaultState, () => {
    const user = localStorage.getItem('loggedInUser')
    return user ? { loggedInUser: JSON.parse(user) } : defaultState;
  })


  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuth };
