import {Fragment, useEffect, useState} from "react";
import {apiVerifyEmail} from "api/auth";
import {useParams, Link} from "react-router-dom";
//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";

const VerifyEmail = () => {
  const [message, setMessage] = useState<any>("");
  type viewParams = {
    token: string;
  };
  const {token} = useParams<viewParams>();
  useEffect(() => {
    apiVerifyEmail(token).then((responseData: any) => {
      if (responseData.status === "success") {
        setMessage(
          <h4>
            <i className="fa fa-check-circle"></i>Your email has been verified.
          </h4>
        );
      } else {
        setMessage(
          <h4>
            <i className="fa fa-times-circle"></i>Your email has not been
            verified.
          </h4>
        );
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form">
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap verify-wrap">
            {message}

            <Link to="/login" className="common-btn btn-bg-secondary-color">
              Login
            </Link>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default VerifyEmail;
