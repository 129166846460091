import { SvgIcon } from "@material-ui/core";

export function FlagSE(): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} >
      <g>
        <g className="st0" clipPath="url(#SVGIDD_2_)">
          <circle fill="#005293" cx="16" cy="16" r="16"/>
          <rect fill="#FECB00" x="9" y="0" width="5" height="32"/>
          <rect fill="#FECB00" x="0" y="13" width="32" height="5"/>
        </g>
      </g>
    </SvgIcon>
  );
}
