/* eslint-disable max-len */

import { SvgIcon } from "@material-ui/core";

export function FlagUS(): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }} >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g
              transform="translate(-24.000000, -137.00) translate(0.0, 107.0) translate(24.0, 30.0)">
              <circle cx="16" cy="16" r="16" fill="#F0F0F0"/>
              <g fill="#D80027">
                <path
                  d="M14.753 12.522H31.45c0-1.444-.193-2.843-.551-4.174H14.753v4.174zM14.753 4.174H29.1C28.121 2.576 26.87 1.163 25.41 0H14.753v4.174zM15.449 28.522c3.765 0 7.226-1.302 9.96-3.478H5.489c2.733 2.176 6.194 3.478 9.96 3.478zM1.797 20.87H29.1c.787-1.284 1.397-2.686 1.798-4.174H0c.401 1.488 1.01 2.89 1.797 4.174z"
                  transform="translate(0.551187, 3.478250)"
                />
              </g>
              <path
                fill="#0052B4"
                d="M7.412 2.499H8.87l-1.357.985.518 1.594-1.356-.985-1.356.985.447-1.377c-1.194.995-2.24 2.16-3.103 3.459h.467l-.863.627c-.134.224-.263.452-.387.683l.412 1.27-.769-.56c-.191.405-.366.82-.523 1.242l.454 1.398H3.13l-1.356.986.518 1.594-1.356-.985-.812.59C.042 14.659 0 15.325 0 16h16V0c-3.16 0-6.107.917-8.588 2.499zm.62 11.901l-1.357-.985-1.356.985.518-1.594-1.356-.986h1.676l.518-1.594.518 1.594H8.87l-1.357.986.518 1.594zm-.519-6.255l.518 1.594-1.356-.985-1.356.985.518-1.594-1.356-.985h1.676l.518-1.595.518 1.595H8.87l-1.357.985zm6.258 6.255l-1.357-.985-1.356.985.518-1.594-1.356-.986h1.676l.518-1.594.518 1.594h1.677l-1.357.986.518 1.594zm-.519-6.255l.518 1.594-1.356-.985-1.356.985.518-1.594-1.356-.985h1.676l.518-1.595.518 1.595h1.677l-1.357.985zm0-4.661l.518 1.594-1.356-.985-1.356.985.518-1.594-1.356-.985h1.676l.518-1.595.518 1.595h1.677l-1.357.985z"/>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
