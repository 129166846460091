import {Fragment} from "react";
import {Link} from "react-router-dom";

//Image Import
import LoginLogo from "assets/images/login/login-logo.svg";
import ForwardIcon from "assets/images/login/forward-icon.svg";
import PadLockIcon from "assets/images/login/pad-lock-icon.svg";

const SuccessPassword = () => {
  return (
    <Fragment>
      <div className="login-wrapper">
        <form className="login-form floating-form">
          <div className="login-logo-wrap">
            <img
              src={LoginLogo}
              alt="myXR CMS"
              className="d-inline-block align-text-top"
            />
          </div>
          <div className="login-info-wrap">
            <h4>Success!</h4>
            <span>Your password has now been updated.</span>
          </div>

          <div className="success-img-wrap">
            <img src={PadLockIcon} alt="success lock icon" />
          </div>

          <div className="reset-token-text mb-4">
            Please return to the sign in page using the button below.
          </div>
          <div className="login-footer justify-content-end">
            <Link
              to="/login"
              className="common-btn btn-bg-secondary-color pull-right"
            >
              Continue
              <img
                className="token-icon"
                src={ForwardIcon}
                alt="forward icon"
              />
            </Link>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default SuccessPassword;
