import { Fragment } from "react";

const Dashboard = () => {
  return (
    <Fragment>
      <div className="main-content container-fluid">
      <div className="common-overview-header">
					<div className="lerners-info">
						<h2>Dashboard</h2>
            <span>2246</span>
					</div>
				</div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
