import axios from "axios";
export const fileUploader = async (files:File) => {
    try {
      // this.setState({ errorMessage: "" });
      console.log("fileUploader called", files);
      let formData = new FormData();
      formData.append("fileToSend", files);
      formData.append("client", "BT101");
      formData.append("bucket", "video-communication");
  
      const UPLOADER = `https://awsuploader.services.olive.media`;
      axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["Access-Control-Allow-Headers"] =
        "Origin, X-Requested-With, Content-Type, Accept";
      axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      console.log("sending..............", formData);
      return axios
        .post(`${UPLOADER}/upload`, formData)
        .then((res) => res.data.data[0].fileToSend.Location)
        .catch((error) => error);
    } catch (error:any) {
      console.log("error in uploader", JSON.parse(JSON.stringify(error)));
      // return error;
  
      if (error.response) {
        console.log("error in uploader res", error);
      } else {
        console.log("504 error", error);
      }
  
      return error;
    }
  };