import {Fragment, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {apiGetUser} from "api/user";
import LoginHistory from "./loginHistory";
import LearnerProgress from "./learnerProgress";
// import SingleLearner from "screens/learners/addLearner/singleLearner";

//images
import FiltersIcon from "assets/images/common/filters-icon.svg";
import AngleLeftBlackIcon from "assets/images/common/angle-left-black-icon.svg";

const ViewLearner = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tab, setTab] = useState("learner_progress");
  type viewParams = {
    id: string;
  };
  // const [isEditLearner, setIsEditLearner] = useState<string[]>([]);
  const {id} = useParams<viewParams>();
  useEffect(() => {
    apiGetUser(id).then((responseData: any) => {
      if (responseData.status === "success") {
        let user = responseData.user;
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
      }
    });
  }, []);
  return (
    <Fragment>
      <div className="main-content container-fluid">
        <div className="common-overview-header">
          <div className="lerners-info">
            <h2>
              {firstName} {lastName}
            </h2>
            <span>{email}</span>
          </div>
          <div className="button-wrap">
            <button className="common-btn btn-border-color">
              Delete Learner
            </button>
            <button
              className="common-btn btn-bg-green-color ml-20"
              // onClick={() => {
              //   setIsEditLearner([id]);
              // }}
            >
              Edit Learner
            </button>
          </div>
        </div>

        <div className="overview-table-header">
          <h4>Learner Overview</h4>
          <Link className="overview-back" to="/learners/overview">
            <img src={AngleLeftBlackIcon} alt="icon" />
            Back
          </Link>
        </div>

        <div className="overview-table-block">
          <div className="overview-tab-holder">
            <ul
              className="nav nav-tabs common-nav-tabs"
              id="overviewTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="learner-progress-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#learner-progress"
                  type="button"
                  role="tab"
                  aria-controls="learner-progress"
                  aria-selected="true"
                  onClick={() => {
                    setTab("learner_progress");
                  }}
                >
                  Learner Progress
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="login-history-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#login-history"
                  type="button"
                  role="tab"
                  aria-controls="login-history"
                  aria-selected="false"
                  onClick={() => {
                    setTab("login_history");
                  }}
                >
                  Login History
                </button>
              </li>
            </ul>
            <a className="show-filters" href="javascript:void(0);">
              <img src={FiltersIcon} alt="icon" />
              Show Filters
            </a>
          </div>
          {tab === "login_history" ? <LoginHistory /> : <LearnerProgress />}
        </div>
      </div>
      {/* if(isEditLearner.length)
      {
        <SingleLearner
          isEditLearner={isEditLearner}
          setIsEditLearner={setIsEditLearner}
        />
      } */}
    </Fragment>
  );
};

export default ViewLearner;
