import { Fragment, useEffect } from 'react';
import { NavBar, SideBar } from 'components';
import {
	Dashboard,
	Learners,
	Passwords,
	ViewLearner,
	SectionProgress,
	Overflow,
	ContentOverview,
	ContentMenu,
	EditSceneContent,
} from 'screens';
import { useHistory } from 'react-router';
import { error } from 'utils/toast';
import jwt_decode from 'jwt-decode';

const Wrapper = ({ page }: { page: string }) => {
	const history = useHistory();
	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token === null) {
			localStorage.clear();
			error('Please login');
			history.push('/login');
		} else {
			interface Token {
				name: string | null;
				exp: number;
			}
			let decodedToken = jwt_decode<Token>(token || '');
			if (decodedToken.exp < Date.now() / 1000) {
				localStorage.clear();
				error('Please login');
				history.push('/login');
			}
		}
	}, [history]);

	const renderScreens = () => {
		switch (page) {
			case 'dashboard':
				return <Dashboard />;
			case 'learners/overview':
				return <Learners />;
			case 'learners/passwords':
				return <Passwords />;
			case 'learners/viewLearner':
				return <ViewLearner />;
			case 'reports/section-progress':
				return <SectionProgress />;
			case 'reports/overflow':
				return <Overflow />;
			case 'content/overview':
				return <ContentOverview />;
			case 'content/content-menu':
				return <ContentMenu />;
			case 'content/content-menu/edit-scene-content':
				return <EditSceneContent />;
		}
	};
	return (
		<Fragment>
			<NavBar />
			<main className="main-wrapper">
				<SideBar />
				{renderScreens()}
			</main>
		</Fragment>
	);
};
export default Wrapper;
