/* eslint-disable max-len */

import { SvgIcon } from "@material-ui/core";

export function FlagGB(): JSX.Element {
  return (
    <SvgIcon fontSize="inherit" viewBox={"0 0 32 32"} style={{ width: 32, height: 32 }}>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g transform="translate(-24.000000, -54.000000) translate(24.000000, 54.000000)">
            <circle cx="16" cy="16" r="16" fill="#F0F0F0" />
            <g fill="#0052B4">
              <path
                d="M2.756 5.708C1.5 7.343.552 9.228 0 11.275h8.324L2.756 5.708zM30.898 11.275c-.552-2.047-1.5-3.932-2.757-5.567l-5.567 5.567h8.324zM0 19.623c.552 2.047 1.5 3.932 2.756 5.567l5.567-5.567H0zM25.19 2.756C23.555 1.5 21.67.552 19.623 0v8.324l5.567-5.568zM5.708 28.141c1.635 1.257 3.52 2.205 5.567 2.757v-8.324l-5.567 5.567zM11.275 0C9.227.552 7.343 1.5 5.708 2.756l5.567 5.567V0zM19.623 30.898c2.047-.552 3.932-1.5 5.567-2.757l-5.567-5.567v8.324zM22.574 19.623l5.567 5.567c1.257-1.635 2.205-3.52 2.757-5.567h-8.324z"
                transform="translate(0.551187, 0.551187)" />
            </g>
            <g fill="#D80027">
              <path
                d="M31.865 13.913H18.087V.135C17.404.046 16.707 0 16 0c-.707 0-1.404.046-2.087.135v13.778H.135C.046 14.596 0 15.293 0 16c0 .707.046 1.404.135 2.087h13.778v13.778c.683.088 1.38.135 2.087.135.707 0 1.404-.046 2.087-.135V18.087h13.778c.088-.683.135-1.38.135-2.087 0-.707-.047-1.404-.135-2.087z" />
              <path
                d="M20.174 20.174l7.14 7.14c.328-.329.641-.672.94-1.027l-6.112-6.113h-1.968zM11.826 20.174l-7.14 7.14c.329.328.672.641 1.027.94l6.113-6.113v-1.967zM11.826 11.826l-7.14-7.14c-.328.329-.641.672-.94 1.027l6.112 6.113h1.968zM20.174 11.826l7.14-7.14c-.329-.328-.672-.641-1.027-.94l-6.113 6.113v1.967z" />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
