import { useState, Fragment, useEffect } from "react";
import Moment from "react-moment";
import Loading from "components/Loader";
import { apiFetchCentralPasswordLogs } from "api/user";
import { Link } from "react-router-dom";
//images
import TickIcon from "assets/images/common/tick-icon.svg";
import ViewLogIcon from "assets/images/common/view-users-icon.svg";
import ViewLogModal from "components/modal/viewLogModal/viewLogModal";
const PasswordLogs = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState([]);
  const [activeLogForModal, setActiveLogForModal] = useState<any>(null);

  interface LogsData {
    agent: string;
    actor: {
      first_name: string;
      last_name: string;
      _id: string;
    };
    createdAt: string;
  }

  useEffect(() => {
    setisLoading(true);
    apiFetchCentralPasswordLogs().then((data) => {
      setisLoading(false);
      if (data.data.logs) {
        setLogs(data.data.logs);
      }
    });
  }, []);
  return (
    <div className="common-box">
      <h4 className="form-custom-label">Password Change Logs</h4>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {logs.map((log: LogsData) => (
            <div className="password-log-list" key={log.actor?._id}>
              <div className="password-log-info">
                <div className="common-circle-wrap bg-primary-color">
                  <img src={TickIcon} alt="tick icon" />
                </div>
                <div className="info-wrap">
                  <h4>
                    {log.actor?.first_name} {log.actor?.last_name}
                  </h4>
                  <span>
                    Password Change:{" "}
                    <Moment format="DD/MM/YY HH:mm">{log.createdAt}</Moment>
                  </span>
                  <span className="mt-2">
                    Agent:{" "}
                    {log.agent === 'system' ? `System` :
                      log.actor?.first_name + ' '+ log.actor?.last_name
                    }
                  </span>
                </div>
              </div>
              <button className="password-log-view" onClick={(e) => setActiveLogForModal(log)} >
                <img src={ViewLogIcon} alt="icon" />
                <span>View Log</span>
              </button>
            </div>
          ))}
          {activeLogForModal && <ViewLogModal log={activeLogForModal} setActiveLogForModal={setActiveLogForModal} />}
        </Fragment>
      )}
    </div>
  );
};
export default PasswordLogs;
