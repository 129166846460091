const CircleChart = ({
	strokeWidth,
	percentage,
}: {
	strokeWidth: number;
	percentage: number;
}) => {
	const radius = 50 - strokeWidth / 2;
	const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

	const diameter = Math.PI * 2 * radius;
	const progressStyle = {
		stroke: '#855CF8',
		strokeDasharray: `${diameter}px ${diameter}px`,
		strokeDashoffset: `${((100 - percentage) / 100) * diameter}px`,
	};

	return (
		<svg
			className={'CircularProgressbar'}
			viewBox="0 0 100 100"
			width={208}
			height={208}
		>
			<path
				className="CircularProgressbar-trail"
				d={pathDescription}
				strokeWidth={strokeWidth}
				fillOpacity={0}
				style={{
					stroke: '#CFD8DC',
				}}
			/>

			<path
				className="CircularProgressbar-path"
				d={pathDescription}
				strokeWidth={strokeWidth}
				fillOpacity={0}
				style={progressStyle}
			/>

			<text
				className="CircularProgressbar-text"
				x={50}
				y={55}
				style={{
					fill: '#1B1C21',
					fontFamily: 'Helvetica',
					fontSize: '24px',
					fontWeight: 'bold',
					dominantBaseline: 'text-after-edge',
					textAnchor: 'middle',
				}}
			>
				{`${percentage}%`}
			</text>
			<text
				className="CircularProgressbar-text"
				x={50}
				y={60}
				style={{
					fill: '#1B1C21',
					fontFamily: 'Helvetica',
					fontSize: '12px',
					dominantBaseline: 'text-before-edge',
					textAnchor: 'middle',
				}}
			>
				Active
			</text>
		</svg>
	);
};

export default CircleChart;
