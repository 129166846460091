import React from "react";
import ReactDOM from "react-dom";
import App from "./routes";
import { AppProviders } from "context";
import { AuthProvider } from "context/authContext";
import { TranslationProvider } from "context/translationContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
declare global {
  interface Array<T> {
    remove(elem: T): Array<T>;
  }
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function <T>(this: T[], elem: T): T[] {
    const index = this.indexOf(elem);
    if (index > -1) {
      this.splice(index, 1);
    }
    return this;
  };
}

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <TranslationProvider>
        <AuthProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        </AuthProvider>
      </TranslationProvider>
      
    </AppProviders>
  </React.StrictMode>,
  document.getElementById("root")
);
