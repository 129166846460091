import { AxiosPromise, AxiosResponse } from 'axios';

import { client } from 'api/config';
import { ApiRequestUrl, ApiErrorStatusCode } from 'api/constants';
import { error } from "utils/toast";
export interface IRequestError {
  status: number,
  text: string,
}

const parseResult = (result: AxiosResponse): AxiosResponse['data'] => result && result.data;

const onError = (Response: any): IRequestError => {

  let response = Response?.response?.data

  if (typeof response.error === 'object') {
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  } else {
    error(response.error)

  }

  switch (response.statusCode) {
    case ApiErrorStatusCode.BAD_REQUEST:
      return {
        status: response.statusCode,
        text: response.message,
      };
    case ApiErrorStatusCode.UNAUTHORIZEDS:
      return {
        status: response.statusCode,
        text: 'Authorisation error',
      };
    case ApiErrorStatusCode.NOT_FOUND:
      return {
        status: response.statusCode,
        text: 'Not found',
      };
    case response.statusCode >= ApiErrorStatusCode.INTERNAL_STATUS_ERROR:
      return {
        status: response.statusCode,
        text: 'It seems something went wrong. Please, try later.',
      };
    default:
      return {
        status: response.statusCode,
        text: response.message,
      };
  }
};

export const apiGetActiveUsers = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'GET',
    url: `${ApiRequestUrl.GET_ACTIVE_USERS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetDailyLogins = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_DAILY_LOGINS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetNewAccounts = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_NEW_ACCOUNTS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetTotalLearnerProgress = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'GET',
    url: `${ApiRequestUrl.GET_TOTAL_LEARNER_PROGRESS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetExperienceSections = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'GET',
    url: `${ApiRequestUrl.GET_EXPERIENCE_SECTIONS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetLocationProgress = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'GET',
    url: `${ApiRequestUrl.GET_LOCATION_PROGRESS}`,
  })
    .then(parseResult)
    .catch(onError)
);
