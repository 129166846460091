import { AxiosPromise, AxiosResponse } from 'axios';

import { client } from 'api/config';
import { ApiRequestUrl, ApiErrorStatusCode } from 'api/constants';
import { error } from "utils/toast";
export interface IRequestError {
  status: number,
  text: string,
}

const parseResult = (result: AxiosResponse): AxiosResponse['data'] => result && result.data;

const onError = (Response:any): IRequestError => {

  let response =Response?.response?.data

  if(typeof response.error === 'object'){
    Object.keys(response.error).map((key: string) => (error(response.error[key])));
  }else{
    error(response.error)
    
  }
  
  switch (response.statusCode) {
    case ApiErrorStatusCode.BAD_REQUEST:
      return {
        status: response.statusCode,
        text: response.message,
      };
    case ApiErrorStatusCode.UNAUTHORIZEDS:
      return {
        status: response.statusCode,
        text: 'Authorisation error',
      };
    case ApiErrorStatusCode.NOT_FOUND:
      return {
        status: response.statusCode,
        text: 'Not found',
      };
    case response.statusCode >= ApiErrorStatusCode.INTERNAL_STATUS_ERROR:
      return {
        status: response.statusCode,
        text: 'It seems something went wrong. Please, try later.',
      };
    default:
      return {
        status: response.statusCode,
        text: response.message,
      };
  }
};

export const apiGetUserList = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_LEARNERS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiGetUser = (id:string): AxiosPromise => (
  client({
    method: 'GET',
    url: `admin/users/${id}/get`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiEditUser = (data = {},id:string): AxiosPromise => (
  client({
    data,
    method: 'PUT',
    url: `admin/users/${id}/edit`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiSignUp = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.LEARNER_SIGN_UP}`,
  })
    .then(parseResult)
    .catch(onError)
);


export const apiBulkImport = (data:any): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.LEARNER_BULK_UPLOAD}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiDeleteUsers = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.DELETE_USERS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiSignIn = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.SIGN_IN}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiCentralPasswordUpdate = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.CENTRAL_PASSWORD_UPDATE}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiFetchCentralPasswordLogs = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.CENTRAL_PASSWORD_LOGS}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiFetchSystemSetting = (data = {"key": "central-password-auto-update-cycle"}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_SYSTEM_SETTING}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiCreateSystemSetting = (data = {}): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.CREATE_SYSTEM_SETTING}`,
  })
    .then(parseResult)
    .catch(onError)
);

export const apiLoginHistory = (data={},id:string): AxiosPromise => (
  client({
    data,
    method: 'POST',
    url: `${ApiRequestUrl.GET_LOGIN_HISTORY.replace(":id",id)}`,
  })
    .then(parseResult)
    .catch(onError)
);

