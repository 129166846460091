import { Fragment } from 'react';

const FloatingDescription = ({title,value,content,disabled= false, handleChange} : {title: string, value: string, content: any, disabled: boolean, handleChange: Function}) => {
	return (
		<Fragment>
			<textarea
				className="form-control textarea-control"
				placeholder={title}
				value={value}
				name={content.translation}
				onChange={(e)=>handleChange(e)}
				disabled={disabled}
				id="floatingTextarea2"
			></textarea>
			<label htmlFor="floatingTextarea2">{title}</label>
		</Fragment>
	);
};

export default FloatingDescription;
