import { Fragment } from 'react';
import {Link} from "react-router-dom";
//images
import PageNotFoundIcon from 'assets/images/common/page-not-found-icon.svg';

const PageNotFound = () => {
	return (
		<Fragment>
			<div className="page-not-found-wrapper">
				<div className="page-not-found">
					<img src={PageNotFoundIcon} alt="page not found icon" />
					<div className="page-not-found-info">
						<h1>
							Oops!
							<br />
						</h1>
						Page Not Found!
						<br />
						<Link to="/">Hitch a ride back home.</Link>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default PageNotFound;
