import { useState, useEffect } from 'react';
import SwitchToggle from 'components/switchToggle';
import { success } from 'utils/toast';
import { useForm } from 'react-hook-form';
import { apiFetchSystemSetting, apiCreateSystemSetting } from 'api/user';
import moment from 'moment';
//images

import NotAValidIcon from 'assets/images/common/not-valid-icon.svg';

const AutomaticPassword = () => {
	const [
		enableAutomaticPasswordGeneration,
		setEnableAutomaticPasswordGeneration,
	] = useState<boolean>(false);
	const [interval, setInterval] = useState<number>(0);
	const [startDate, setStartDate] = useState<string>('');
	const [endDate, setEndDate] = useState<string>('');

	useEffect(() => {
		apiFetchSystemSetting().then((responseData: any) => {
			if (responseData.status === 'success') {
				setEnableAutomaticPasswordGeneration(
					responseData.data[0].value.isActive
				);
				setInterval(responseData.data[0].value.interval);
				setStartDate(
					moment(responseData.data[0].value.start_date).format(
						'YYYY-MM-DD HH:MM:ss'
					)
				);
				let endDate = moment(responseData.data[0].value.start_date).add(
					responseData.data[0].value.interval,
					'days'
				);
				setEndDate(moment(endDate).format('YYYY-MM-DD HH:MM:ss'));
				setValue('interval', responseData.data[0].value.interval);
			}
		});
	}, []);

	const handleToggle = () => {
		setEnableAutomaticPasswordGeneration(!enableAutomaticPasswordGeneration);
	};

	interface automaticUpdateData {
		start_date: string;
		isActive: boolean;
		interval: number;
	}
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<automaticUpdateData>();

	const onSubmit = handleSubmit((data: automaticUpdateData) => {
		data.isActive = enableAutomaticPasswordGeneration;
		data.start_date = moment().format('YYYY-MM-DDTHH:MM:ss.SSSZ');
		let payLoadData = {
			key: 'central-password-auto-update-cycle',
			value: data,
		};
		apiCreateSystemSetting(payLoadData).then((responseData: any) => {
			if (responseData.status === 'success') {
				success(responseData.data);
				reset();
			}
		});
	});

	return (
		<div className="common-box">
			<div className="enable-password-wrap">
				<div className="enable-password-info">
					<h4>Enable Automatic Password Generation</h4>
					<span>
						Add the number of days before a password renewal is created
					</span>
				</div>
				<SwitchToggle
					isOn={enableAutomaticPasswordGeneration}
					onColor="#6F75BC"
					handleToggle={handleToggle}
				/>
			</div>
			<form className="floating-form enable-password-form" onSubmit={onSubmit}>
				<div className="form-floating">
					<input
						type="number"
						className="form-control"
						placeholder="Number of days"
						{...register('interval', {
							required: 'Interval is required',
						})}
					/>
					<label htmlFor="floatingInput">Number of days</label>
					{errors.interval && (
						<div className="error-msg">
							<img src={NotAValidIcon} alt="icon" />
							<span>{errors.interval.message}</span>
						</div>
					)}
					<div className="enable-date-text">
						<span><strong>Start Date:</strong> {startDate}</span>
						<span><strong>Next Date:</strong> {endDate}</span>
					</div>
				</div>

				<div className="update-password-wrap">
					<button className="common-btn btn-bg-green-color">Update</button>
				</div>
			</form>
		</div>
	);
};
export default AutomaticPassword;
