
export const ApiRequestUrl = {
  BASE: process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'https://dev.xr-cms.olivegroup.io/api/',
  //ADMIN
  GET_LEARNERS: 'admin/users/list',
  DELETE_USERS:"admin/users/delete-many",
  LEARNER_BULK_UPLOAD: "admin/users/bulk-upload",
  CENTRAL_PASSWORD_LOGS: "admin/central-password/logs",
  CENTRAL_PASSWORD_UPDATE: "admin/central-password/update",
  GET_LOGIN_HISTORY: "admin/users/:id/login-history",
  //SYSTEM
  GET_SYSTEM_SETTING: "system-settings/get",
  CREATE_SYSTEM_SETTING:"system-settings/create",
  //AUTH
  SIGN_IN: "auth/signin",
  LEARNER_SIGN_UP: "auth/signup",
  FORGOT_PASSWORD:"auth/forgot-password",
  RESET_PASSWORD:"auth/reset-password/",
  VERIFY_EMAIL:"auth/verify-email/",
  //SECTIONS
  GET_ALL_SECTION_PROGRESS: "section-progress/get-all",
  //ANALYTICS
  GET_ACTIVE_USERS: "analytics/active-users",
  GET_DAILY_LOGINS: "analytics/daily-logins",
  GET_NEW_ACCOUNTS: "analytics/new-accounts",
  GET_TOTAL_LEARNER_PROGRESS: "analytics/total-learner-progress",
  GET_LOCATION_PROGRESS: "analytics/learner-progress-groupby-location",
  GET_EXPERIENCE_SECTIONS: "analytics/experience-section",
  GET_CONTENT_OVERVIEW: "/admin/section-group/list",
  GET_ALL_TRANSLATIONS: '/translation/get/all',
  UPDATE_TRANSLATIONS: '/translation/update',
};

export const ApiErrorStatusCode = {
  BAD_REQUEST: 400,
  UNAUTHORIZEDS: 401,
  NOT_FOUND: 404,
  INTERNAL_STATUS_ERROR: 500,
};
