import {Fragment, useEffect, useState} from "react";
import Loading from "components/Loader";
import {useParams} from "react-router-dom";
import Moment from "react-moment";
import {apiLoginHistory} from "api/user";

//images
import LongArrowUpIcon from "assets/images/common/long-arrow-up-icon.svg";

const LoginHistory = () => {
  const [loginhistories, setLoginHistories] = useState([]);
  const [isLoading, setisLoading] = useState<boolean>(false);
  //pagination
  const [limit, setLimit] = useState<number>(10);
  const [skip, setSkip] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  type viewParams = {
    id: string;
  };
  const {id} = useParams<viewParams>();
  useEffect(() => {
    let data = {
      skip,
      limit,
    };

    setisLoading(true);
    apiLoginHistory(data, id).then((responseData: any) => {
      setisLoading(false);

      if (responseData.status === "success" && responseData.data.userLogins) {
        setLoginHistories(responseData.data.userLogins);
        setPageCount(responseData.data.meta.pageCount);
        setHasNextPage(responseData.data.meta.hasNextPage);
        setHasPrevPage(responseData.data.meta.hasPrevPage);
      }
    });
  }, [skip, limit]);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : loginhistories.length === 0 ? (
        <span className="no-learners-found">"No Login History Found"</span>
      ) : (
        <Fragment>
          <div className="tab-content" id="overviewTabContent">
            <div
              className="tab-pane fade show active"
              id="login-history"
              role="tabpanel"
              aria-labelledby="login-history-tab"
            >
              <div className="table-responsive overview-table-list login-history-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="thead-text">Last Accessed</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loginhistories.map((loginHistory: {createdAt: string}) => (
                      <tr>
                        <td>
                          <div className="info-wrap">
                            <div className="login-history-wrap">
                              <span>
                                <Moment format="DD/MM/YY">
                                  {loginHistory.createdAt}
                                </Moment>
                              </span>
                              <span>
                                <Moment format="HH:mm">
                                  {loginHistory.createdAt}
                                </Moment>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overview-table-footer inner-table-footer">
            <div className="row-page-wrap">
              <h4>Rows per page</h4>
              <select
                className="form-select"
                aria-label="Default select example"
                value={limit}
                onChange={(event: any) => {
                  setLimit(event.target.value);
                  setSkip(1);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="custom-pagination">
              <div className="pagination-wrap">
                <a
                  href="javascript:void(0);"
                  onClick={(event: any) =>
                    hasPrevPage ? setSkip(skip - 1) : ""
                  }
                  className={hasPrevPage ? "" : "disabled"}
                >
                  <svg
                    width="8"
                    height="16"
                    viewBox="0 0 8 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.99707 1.59033L1.99707 8.12158L6.99707 14.6538"
                      stroke="#6F75BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <input
                  type="text"
                  className="form-control"
                  value={skip}
                  disabled
                />
                <a
                  href="javascript:void(0);"
                  onClick={(event: any) =>
                    hasNextPage ? setSkip(skip + 1) : ""
                  }
                  className={hasNextPage ? "" : "disabled"}
                >
                  <svg
                    width="8"
                    height="15"
                    viewBox="0 0 8 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 13.9258L6.25488 7.71484L1.5 1.50098"
                      stroke="#6F75BC"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <span className="showing-page">of {pageCount}</span>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LoginHistory;
